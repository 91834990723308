import Missing from './components/Missing';
import { Routes, Route, useLocation } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import RegisterCarousel from './components/RegisterCarousel';
import ThreeStepForm from './components/MultistepForm';
import Login2 from './components/Login2';
import EmailVerification from './components/Emailverification';
import Ticket from './components/Tickets';
import { useState, useEffect } from 'react';
import Meetings from './components/Meetings';
import MeetingDash from './components/MeetingDash';
import Testing from './components/Testing';
import IdentityDash from './components/IdentityDash'
import io from 'socket.io-client';
import IdentityProducts from './components/IdentityProducts';
import Identity from './components/Identity';
import IdentityRegistration from './components/Views/IdentityRegistsration';
import ProtectedRoute from './components/ProtectedRoutes';
import CardDesign from './components/Views/CardDesign';
import Documents from './components/Views/Documents';
import ConsentPage from './components/Views/ConsentPage';
import Memberships from './components/Views/Memberships';
import Loyalty from './components/Views/Loyalty';
import SettingsCard from './components/Views/Settings';
import GeneralSettings from './components/GeneralSettings';
import { validateLocation } from './Utilities/utilities';
import CenteredFlagMessage from './components/LocationPage';
import CreateInvoice from './components/Views/CreateInvoice';
import PaymentPage from './components/Views/PaymentConfirmation';
import TagTest from './components/TagGeneratorTest';
import OrderTags from './components/Views/OrderTags';
import Loader from './components/Loader';
import { apiBaseUrl } from './Utilities/globals';
import PassCard from './components/Views/Pass';
import PassCategory from './components/PassCategory';
import EventMenu from './components/Views/EventMenu';
import EventsOutlet from './components/Views/EventsOutlet';
import EventForm from './components/Views/EventForm';
import AssetManagementCard from './components/Views/AssetManagement';
import AssetsDashboard from './components/Views/AssetsDashboard';
import UserEvents from './components/Views/UserEvent';
import EventCredentialForm from './components/Views/EventCredentialForm';
import UserEventPasses from './components/Views/UserEventPasses';
import AssetsOperationOutlet from './components/Views/AssetsOperationsOutlet';
import AssetOperationsMenu from './components/Views/AssetsOperationsMenu';
import BranchForm from './components/Views/BranchForm';
import UserBranches from './components/Views/UserBranches';
import AssetsBranchOutlet from './components/Views/AssetsBranchOutlet';
import BranchLoginPage from './components/Views/branchLogin';
import BranchHome from './components/Views/BranchHome';
import ProtectedBranchRoute from './components/ProtectedBranchRoute';
import AssetsBranchDash from './components/Views/AssetsBranchDash';
import EventCouponForm from './components/Views/EventCouponForm';
import EventZoneForm from './components/Views/EventZoneForm';
import IdentityPass from './components/Views/IdentityPass';
import EventManagementCard from './components/Views/EventManagementCard';
import EventAgenda from './components/Views/EventAgendaDemo';
import EventStatistics from './components/eventStatistics';
import SrhrAgenda from './components/Views/Srhrdemo';
import EventManagementHome from './components/Views/EventManagementHome';
import EventsManagementOutlet from './components/Views/EventManagementOutlet';
import CreatedEvents from './components/Views/CreatedEvents';
import CreateEventForm from './components/Views/CreateEventForm';
import EventManagementAddsOn from './components/Views/EventManagementAddsOn';
import UpdateEventForm from './components/Views/EventUpdateForm';
import PassCategoryForm from './components/Views/CreatePassCategoryForm';
import PassVerification from './components/Views/PassVerification';
import TagPurchase from './components/Views/TagPurchase';
import BillingOutlet from './components/Views/BillingOutlet';
import BillingOverview from './components/Views/BillingOverview';
import BillingAccounts from './components/Views/BillingAccounts';
import EventsAddsOnOutlet from './components/Views/EventsAddsOnOutlet';
import DescriptionPage from './components/Views/EventsAddsonDesc';
import BillsSummary from './components/Views/BillsSummary';
import CreditsSummary from './components/Views/CreditsSummary';
import BillingPurchaseOrders from './components/Views/BillingPuchaseOrders';
import Home from './components/Views/Home';
import EventSponsorForm from './components/Views/EventSponsorsForm';
import TicketPassVerification from './components/Views/TicketPassVerification';
import CollectionAccountSettings from './components/Views/CollectionAccountSettings';
import ProtectedInnerRoute from './components/ProtectedInnerRoute';
import CollectionsOutlet from './components/Views/CollectionsOutlet';
import TransactionCollections from './components/Views/TransactionCollections';
import EventCollections from './components/Views/EventCollections';
import CashOuts from './components/Views/CashOuts';
import CashOutRequests from './components/Views/CashOutRequests';
import EventsOrdersOutlet from './components/Views/OrdersOutlet';
import EventManagementOrders from './components/Views/EventsManagementOrders';
import PhysicalTagPurchase from './components/Views/PhysicalTagOrder';
import EventsPassAddsonOutlet from './components/Views/EventsPassAddsOnOutlet';
import EventPassAddsOnHome from './components/Views/EventPassAddsOnHome';
import EventPassAddsOnIdentities from './components/Views/EventPassAddsOnIdentites';
import EventBatchManagement from './components/Views/EventBatchManagement';
import EventTicketAddsOnHome from './components/Views/EventTicketAddsOnHome';
import EventTicketSellerForm from './components/Views/CreateEventTicketSellersForm';
import EventTicketSellerManagement from './components/Views/EventTicketSellerManagement';
import EventDonationCollections from './components/Views/EventDonationCollections';
import BatchAssignment from './components/Views/BatchAssignment';
import TicketVendorForm from './components/Views/TicketVendors';
import EventVendorManagement from './components/Views/EventVendorManagement';
import ComplementaryTickets from './components/Views/ComplementaryTickets';
import ReservationRegistration from './components/Views/EventReservationRegistration';
import EventReservations from './components/Views/EventReservations';
import MainLayout from './layouts/MainLayout';
import VehicleManagementOutlet from './components/Vehicle Management/VehicleManagementOutlet';
import VehicleManagementHome from './components/Vehicle Management/VehicleManagementHome';
import PromoCodeForm from './components/Views/PromoCodes';



function App() {

  const [isValidLocation, setIsValidLocation] = useState(false)
  const [socket, setSocket] = useState(null);
  const [locationMessage, setLocationMessage] = useState('')



  useEffect(() => {
    validateLocation().then(() => {
      setIsValidLocation(true);
    }).catch((message) => {
      setLocationMessage(message);
      setIsValidLocation(false)
    })
  }, [])


  //socket configuration
  useEffect(() => {

    const newSocket = io(apiBaseUrl, { transports: ['websocket', 'polling'] });
    setSocket(newSocket);

  }, []);





  if (!isValidLocation) {

    if (!locationMessage) {
      return (<Loader />)
    } else {
      return (<CenteredFlagMessage message={locationMessage} />)
    }

  } else {

    return (
      <div className='App' >

        <div>

          <Routes  >

            {/* Public routes */}

            <Route path="/login" element={<Login2 />} />
            <Route path="/verification" element={<EmailVerification />} />
            <Route path="/identityregistration/:id" element={<IdentityRegistration />} />
            <Route path="/form" element={<ThreeStepForm />} />
            <Route path="/consent/:hid/:rid" element={<ConsentPage />} />
            <Route path="/verifyPayment/:id" element={<PaymentPage />} />
            <Route path="/event/neld/:tag" element={<EventAgenda />} />
            <Route path="/event/srhr/:tag" element={<SrhrAgenda />} />
            <Route path="/pass/verify/:tag" element={<PassVerification />} />
            <Route path="/passticket/registration/:tagType/:tag" element={<TicketPassVerification />} />
            <Route path="/reservation/:eid" element={<ReservationRegistration />} />
            <Route path="/templayout/consent/test" element={<MainLayout />} />
            <Route path="*" element={<ProtectedRoute ><Missing /></ProtectedRoute>} />


            {/* Private routes */}

            <Route path="/" element={<ProtectedRoute ><MainLayout /></ProtectedRoute>} >


              <Route index element={<ProtectedRoute ><Home /></ProtectedRoute>} />
              <Route path="/home" element={<ProtectedRoute ><Home /></ProtectedRoute>} />
              <Route path="dash" element={<ProtectedRoute  ><Dashboard /></ProtectedRoute>} />
              <Route path="carousel" element={<ProtectedRoute  ><RegisterCarousel /></ProtectedRoute>} />
              <Route path="ticket" element={<ProtectedRoute  ><Ticket /></ProtectedRoute>} />
              <Route path="meetings" element={<ProtectedRoute  ><Meetings /></ProtectedRoute>} />
              <Route path="meetingdash" element={<ProtectedRoute  ><MeetingDash /></ProtectedRoute>} />
              <Route path="identitydash" element={<ProtectedRoute  ><IdentityDash dasht={true} /></ProtectedRoute>} />
              <Route path="identitydashProducts" element={<ProtectedRoute  ><IdentityDash prod={true} dasht={false} /></ProtectedRoute>} />
              <Route path="identityProducts" element={<ProtectedRoute  ><IdentityProducts /></ProtectedRoute>} />
              <Route path="human" element={<ProtectedRoute ><Identity origin={false} /></ProtectedRoute>} />
              <Route path="human/myidentities" element={<ProtectedRoute ><Identity origin={"products"} /></ProtectedRoute>} />
              <Route path="human/cardoperations" element={<ProtectedRoute ><Identity origin={"operations"} /></ProtectedRoute>} />
              <Route path="identityupdate/:id" element={<ProtectedRoute ><IdentityRegistration /></ProtectedRoute>} />
              <Route path="carddesign" element={<ProtectedRoute ><CardDesign /></ProtectedRoute>} />
              <Route path="documents" element={<ProtectedRoute ><Documents /></ProtectedRoute>} />
              <Route path="memberships" element={<ProtectedRoute ><Memberships /></ProtectedRoute>} />
              <Route path="loyalty" element={<ProtectedRoute ><Loyalty /></ProtectedRoute>} />
              <Route path="create/invoice" element={<ProtectedRoute ><CreateInvoice /></ProtectedRoute>} />
              <Route path="tagsTest" element={<ProtectedRoute ><TagTest /></ProtectedRoute>} />
              <Route path="ordertags" element={<ProtectedRoute ><OrderTags /></ProtectedRoute>} />

              <Route path="pass" element={<ProtectedRoute ><PassCard /></ProtectedRoute>} >

                <Route index element={<ProtectedRoute ><PassCategory /></ProtectedRoute>} />
                <Route path="event" element={<ProtectedRoute ><EventsOutlet /></ProtectedRoute>}>
                  <Route index element={<ProtectedRoute ><EventMenu /></ProtectedRoute>} />
                  <Route path="form" element={<ProtectedRoute ><EventForm /></ProtectedRoute>} />
                  <Route path="events" element={<ProtectedRoute ><UserEvents /></ProtectedRoute>} />
                  <Route path="credentialform/:eid" element={<ProtectedRoute ><EventCredentialForm /></ProtectedRoute>} />
                  <Route path="eventzoneform/:eid" element={<ProtectedRoute ><EventZoneForm /></ProtectedRoute>} />
                  <Route path="eventcouponform/:eid" element={<ProtectedRoute ><EventCouponForm /></ProtectedRoute>} />
                  <Route path="identitypass" element={<ProtectedRoute ><IdentityPass /></ProtectedRoute>} />
                  <Route path="passes" element={<ProtectedRoute ><UserEventPasses /></ProtectedRoute>} />
                  <Route path="statistics/:eid" element={<ProtectedRoute ><EventStatistics /></ProtectedRoute>} />
                </Route>


              </Route>

              <Route path="events" element={<ProtectedRoute ><EventManagementCard /></ProtectedRoute>} >

                <Route index element={<ProtectedRoute ><div style={{ width: '100%', textAlign: 'center' }}>Events Dashboard</div></ProtectedRoute>} />
                <Route path="products" element={<ProtectedRoute ><EventsManagementOutlet /></ProtectedRoute>}>
                  <Route index element={<ProtectedRoute ><EventManagementHome /></ProtectedRoute>} />
                  <Route path="myEvents" element={<ProtectedRoute ><EventsManagementOutlet /></ProtectedRoute>}>
                    <Route index element={<ProtectedRoute ><CreatedEvents /></ProtectedRoute>} />
                    <Route path="passCheckers/:eid" element={<ProtectedRoute ><EventCredentialForm /></ProtectedRoute>} />
                    <Route path="zoneManagement/:eid" element={<ProtectedRoute ><EventZoneForm /></ProtectedRoute>} />
                    <Route path="statistics/:eid" element={<ProtectedRoute ><EventStatistics /></ProtectedRoute>} />
                    <Route path="couponManagement/:eid" element={<ProtectedRoute ><EventCouponForm /></ProtectedRoute>} />
                    <Route path="passManagement/:eid" element={<ProtectedRoute ><PassCategoryForm /></ProtectedRoute>} />
                    <Route path="sponsorManagement/:eid" element={<ProtectedRoute ><EventSponsorForm /></ProtectedRoute>} />
                    <Route path="batchManagement/:eid" element={<ProtectedRoute ><EventBatchManagement /></ProtectedRoute>} />
                    <Route path="sellerManagement/:eid" element={<ProtectedRoute ><EventTicketSellerManagement /></ProtectedRoute>} />
                    <Route path="vendorManagement/:eid" element={<ProtectedRoute ><EventVendorManagement /></ProtectedRoute>} />
                    <Route path="create" element={<ProtectedRoute ><CreateEventForm /></ProtectedRoute>} />
                    <Route path="update/:eid" element={<ProtectedRoute ><UpdateEventForm /></ProtectedRoute>} />
                    <Route path="complimentaryTicketManagement/:eid" element={<ProtectedRoute ><ComplementaryTickets /></ProtectedRoute>} />
                    <Route path="reservations/:eid" element={<ProtectedRoute ><EventReservations /></ProtectedRoute>} />
                    <Route path="promoCodesManagement/:eid" element={<ProtectedRoute ><PromoCodeForm /></ProtectedRoute>} />
                  </Route>
                </Route>

                <Route path="addson" element={<ProtectedRoute ><EventsAddsOnOutlet /></ProtectedRoute>}>
                  <Route index element={<ProtectedRoute ><EventManagementAddsOn /></ProtectedRoute>} />
                  <Route path="description" element={<ProtectedRoute ><DescriptionPage /></ProtectedRoute>} />

                  <Route path="pass" element={<ProtectedRoute ><EventsPassAddsonOutlet /></ProtectedRoute>}>
                    <Route index element={<ProtectedRoute ><EventPassAddsOnHome /></ProtectedRoute>} />
                    <Route path="identities" element={<ProtectedRoute ><EventPassAddsOnIdentities /></ProtectedRoute>} />
                  </Route>

                  <Route path="ticket" element={<ProtectedRoute ><EventsPassAddsonOutlet /></ProtectedRoute>}>
                    <Route index element={<ProtectedRoute ><EventTicketAddsOnHome /></ProtectedRoute>} />
                    <Route path="ticketSellers" element={<ProtectedRoute ><EventTicketSellerForm /></ProtectedRoute>} />
                    <Route path="assignableTickets" element={<ProtectedRoute ><BatchAssignment /></ProtectedRoute>} />
                    <Route path="vendors" element={<ProtectedRoute ><TicketVendorForm /></ProtectedRoute>} />
                  </Route>



                </Route>


                <Route path="orders" element={<ProtectedRoute ><EventsOrdersOutlet /></ProtectedRoute>}>
                  <Route index element={<ProtectedRoute ><EventManagementOrders /></ProtectedRoute>} />
                  <Route path="digital" element={<ProtectedRoute ><TagPurchase /></ProtectedRoute>} />
                  <Route path="physical" element={<ProtectedRoute ><PhysicalTagPurchase /></ProtectedRoute>} />
                </Route>


              </Route>

              <Route path="travel" element={<ProtectedRoute ><EventManagementCard /></ProtectedRoute>} >

                <Route index element={<ProtectedRoute ><div style={{ width: '100%', textAlign: 'center' }}>Travel Dashboard</div></ProtectedRoute>} />
                <Route path="products" element={<ProtectedRoute ><VehicleManagementOutlet /></ProtectedRoute>}>
                  <Route index element={<ProtectedRoute ><VehicleManagementHome /></ProtectedRoute>} />
                  <Route path="vehicleLayouts" element={<ProtectedRoute ><VehicleManagementOutlet /></ProtectedRoute>}>
                    <Route index element={<ProtectedRoute ><div style={{ width: '100%', textAlign: 'center' }}>Created layouts </div></ProtectedRoute>} />
    
                  </Route>
                </Route>

                <Route path="addson" element={<ProtectedRoute ><EventsAddsOnOutlet /></ProtectedRoute>}>
                  <Route index element={<ProtectedRoute >Travel addson</ProtectedRoute>} />
                  
                </Route>


                <Route path="orders" element={<ProtectedRoute ><EventsOrdersOutlet /></ProtectedRoute>}>
                  <Route index element={<ProtectedRoute >Travel Orders</ProtectedRoute>} />
                 
                </Route>


              </Route>

              <Route path="billing" element={<ProtectedRoute ><BillingOutlet /></ProtectedRoute>} >

                <Route index element={<ProtectedRoute ><BillingOverview /></ProtectedRoute>} />
                <Route path="accounts" element={<ProtectedRoute ><BillingAccounts /></ProtectedRoute>} />
                <Route path="bills" element={<ProtectedRoute ><BillsSummary /></ProtectedRoute>} />
                <Route path="credits" element={<ProtectedRoute ><CreditsSummary /></ProtectedRoute>} />
                <Route path="purchaseOrders" element={<ProtectedRoute ><BillingPurchaseOrders /></ProtectedRoute>} />

              </Route>

              <Route path="collections" element={<ProtectedRoute ><CollectionsOutlet /></ProtectedRoute>} >

                <Route index element={<ProtectedRoute ><ProtectedInnerRoute><TransactionCollections /></ProtectedInnerRoute></ProtectedRoute>} />
                <Route path="events" element={<ProtectedRoute ><ProtectedInnerRoute><EventCollections /></ProtectedInnerRoute></ProtectedRoute>} />
                <Route path="donations" element={<ProtectedRoute ><ProtectedInnerRoute><EventDonationCollections /></ProtectedInnerRoute></ProtectedRoute>} />
                <Route path="cashOuts" element={<ProtectedRoute ><ProtectedInnerRoute><CashOuts /></ProtectedInnerRoute></ProtectedRoute>} />
                <Route path="requests" element={<ProtectedRoute ><ProtectedInnerRoute><CashOutRequests /></ProtectedInnerRoute></ProtectedRoute>} />

              </Route>

              <Route path="assets" element={<ProtectedRoute ><AssetManagementCard /></ProtectedRoute>} >

                <Route index element={<ProtectedRoute ><AssetsDashboard /></ProtectedRoute>} />
                <Route path="operations" element={<ProtectedRoute ><AssetsOperationOutlet /></ProtectedRoute>}>
                  <Route index element={<ProtectedRoute ><AssetOperationsMenu /></ProtectedRoute>} />
                  <Route path="branchform" element={<ProtectedRoute ><BranchForm /></ProtectedRoute>} />
                  <Route path="branches" element={<ProtectedRoute ><UserBranches /></ProtectedRoute>} />
                </Route>


              </Route>

              <Route path="assetBranch/:bid" element={<AssetsBranchOutlet />} >

                <Route index element={<BranchLoginPage />} />
                <Route path="home" element={<ProtectedBranchRoute ><BranchHome /></ProtectedBranchRoute>}>
                  <Route index element={<ProtectedBranchRoute ><AssetsBranchDash /></ProtectedBranchRoute>} />
                  <Route path="form" element={<ProtectedRoute ><EventForm /></ProtectedRoute>} />
                  <Route path="events" element={<ProtectedRoute ><UserEvents /></ProtectedRoute>} />
                  <Route path="credentialform/:eid" element={<ProtectedRoute ><EventCredentialForm /></ProtectedRoute>} />
                  <Route path="passes" element={<ProtectedRoute ><UserEventPasses /></ProtectedRoute>} />
                </Route>


              </Route>


              <Route path="settings" element={<ProtectedRoute ><SettingsCard /></ProtectedRoute>} >

                <Route index element={<ProtectedRoute ><GeneralSettings /></ProtectedRoute>} />
                <Route path="general" element={<ProtectedRoute ><p></p></ProtectedRoute>} />
                <Route path="notifications" element={<ProtectedRoute ><p></p></ProtectedRoute>} />
                <Route path="collections" element={<ProtectedRoute ><ProtectedInnerRoute><CollectionAccountSettings /></ProtectedInnerRoute></ProtectedRoute>} />

              </Route>

              <Route path="/test" element={<Testing />} />

            </Route>





          </Routes>



        </div>

      </div>

    );
  }
}
export default App;