import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../components/NavBar";
import Sidebar from "../components/Dashboard2";
import Mainfooter from "../components/MainFooter";
import useGeneralState from "../hooks/hooks/generalState";
import { fetchData } from "../Utilities/apiRequests";
import Loader from "../components/Loader";
import useInactivitySignOut from "../components/Inactivity";
import { Modal } from "antd";
import Cookies from 'js-cookie';
import { twit } from "../components/Notificationpopout";
import * as MdIcon from 'react-icons/md';

const MainLayout = () => {
  const [open, setOpen] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [profile, setProfile] = useState(null);
  const [loadingProfile, setLoadingProfile] = useState(false);

  const handlesidebar = () => {
    setOpen(!open);
  };


  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);


    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (screenWidth < 1000) {
      setOpen(false);
    }

    if (screenWidth > 1000) {
      setOpen(true);
    }

  }, [screenWidth]);



  const generalAppState = useGeneralState();

  //loading profile
  useEffect(() => {


    fetchData(`/api/auth/user/profile`, setLoadingProfile).then((response) => {
      setProfile(response.data.data)
      generalAppState?.actions.setCurrency(response.data.data.curr)
    }).catch((error) => {
      setLoadingProfile(false)
      console.log('Error loading user profile in main layout:', error.response?.data?.message ? error.response?.data?.message : 'error loading user profile')
    })


  }, []);


  // logout function
  const logOut = () => {
    Cookies.remove('loggedin');
    Cookies.remove('CollAccess');
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = '/';
    twit("info", 'You have been logged out due to inactivity.');
  }

  //inactivity hook
  const { showWarning, isActive, secondsLeft } = useInactivitySignOut(logOut, 1740, 60)


  if (loadingProfile) {

    return (<Loader />)

  } else {


    return (
      <div className="main-layout">


        {showWarning && <Modal open={true} title={'Inactivity Warning.'} closable={false}
          footer={[


          ]}

          className="allmodals"
        >

          <div className='text-muted' style={{ marginTop: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <p style={{ fontSize: '12px' }}>You are about to be signed out in {secondsLeft} seconds.</p>
            <MdIcon.MdTimer className='mx-1' style={{ width: '50px', height: '50px' }} />
            <p style={{ fontSize: '12px' }}>Click anywhere to continue session.</p>
          </div>


        </Modal>}



        <div className="sidebar-container" style={{ width: `${open ? "20%" : "0"}` }}>
          <Sidebar open={open} showSidebar={handlesidebar} />
        </div>
        <div className="navbar-outlet-container" style={{ width: `${open ? '80%' : '100%'}` }}>
          <div style={{ width: "100%" }}>
            <Navbar isopen={open} showSidebar={handlesidebar} profile={profile} />
          </div>

          <div className="main-layout-outlet-container">
            <Outlet />
          </div>

          <div className="main-layout-footer-container">
            <Mainfooter />
          </div>
        </div>
      </div>
    );
  }
};

export default MainLayout;
