import { Button } from "antd";
import React from "react";
import { Card, Col, Container, Form, Row, Table } from "react-bootstrap";
import { twit } from "../Notificationpopout";
import axios from "../../api/axios";
import { fetchData } from "../../Utilities/apiRequests";
import * as MdIcons from "react-icons/md";
import * as RxIcons from "react-icons/rx";
import * as TiIcons from "react-icons/ti";
import * as HiIcons from "react-icons/hi";
import * as GrIcon from "react-icons/gr";
import Loader from "../Loader";
import { useState } from "react";
import { useEffect } from "react";
import ImageCropper from "../ImageCropper";
import ImagePreview from "../ImagePreview";
import Modal from "antd/es/modal/Modal";
import TransparentOverlay from "../TransparentOverlay";
import html2pdf from 'html2pdf.js';
import Invoice from "../Invoice";
import { capitalizeFirstLetter, convertFileToDataURL, getformattedDate, getNairobiTime, removeSpacesAndLowerCase } from "../../Utilities/utilities";
import Pagination from "../Pagination";
import { apiBaseUrl } from "../../Utilities/globals";

const PhysicalTagPurchase = () => {
  const [type, setType] = useState("");
  const [batches, setBatches] = useState("");
  const [color, setColor] = useState("");
  const [typecolors, setTypecolors] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [orders, setOrders] = useState([]);
  const [tagtypes, setTagtypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCropper, setShowCropper] = useState(false);
  const [cropperImage, setCropperImage] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [events, setEvents] = useState([]);
  const [currentColorId, setCurrentColorId] = useState('');
  const [showZoneLoader, setShowZoneLoader] = useState(false);
  const [showInvoice, setShowInvoice] = useState(false);
  const [printable, setPrintable] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [invoiceDate, setInvoiceDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [currentInvoiceData, setCurrentInvoiceData] = useState([]);
  const [totalCost, setTotalCost] = useState(0);
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [showCtgroupsLoader, setShowCtgroupsLoader] = useState(false);

  const purchaseTags = async (setLoading) => {
    const token = sessionStorage.getItem("token");

    const tagOrders = typecolors
      .filter((data) => {
        return data.state == 1;
      })
      .map((data) => {
        return {
          zid: data.zid,
          tcid: data.id,
          batches: data.batches,
          isgeneric: data.isgeneric,
          tagArt: data.tagArt,
          tags: data.tags ? data.tags : 200,
          isComplementary: !(data.isgeneric) ? data.isComplementary : false,
          ctgid: !(data.isgeneric) ? data.ctgid : false,
        }

      });



    for (const tagOrder of tagOrders) {
      if (tagOrder.tagArt) {
        const tagArtdata = await convertFileToDataURL(tagOrder.tagArt)
        tagOrder.tagArt = tagArtdata
      }
    }

    const postData = {
      orderData: tagOrders,
      tagtype: type,
      invoiceNumber,
      cost: totalCost
    };

    const Url = "/api/events/tagpurchase/initiate";
    try {
      setLoading(true);
      const response = await axios.post(Url, JSON.stringify(postData), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setLoading(false);
        return Promise.resolve(response.data);
      }
    } catch (err) {
      setLoading(false);
      console.log("Tag purchase error", err);
      return Promise.reject(
        err.response?.data?.message
          ? err.response?.data?.message
          : "An error occured while initiating tag purchase."
      );
    }
  };

  useEffect(() => {
    fetchData(`/api/events/tagtypes`, setLoading)
      .then((response) => {
        setTagtypes(response.data.tagtypes);
      })
      .catch((error) => {
        twit(
          "info",
          error.response?.data?.message
            ? error.response?.data?.message
            : "error loading tagtypes."
        );
      });

    fetchData(`/api/events/list`, setLoading).then((response) => {

      setEvents(response.data.events.filter((data) => {
        return data?.endtime > getNairobiTime()
      }))

    }).catch((error) => {
      console.log('events list loading error:', error.response?.data?.message ? error.response?.data?.message : 'error loading  events list.')
    })

    fetchData(`/api/events/tagorders`, setLoadingOrders)
      .then((response) => {
        setOrders(response.data.orders);
      })
      .catch((error) => {
        twit(
          "info",
          error.response?.data?.message
            ? error.response?.data?.message
            : "error loading tagtypes."
        );
      });


  }, [refresh]);

  useEffect(() => {
    if (type) {
      const colorData = tagtypes?.filter((st) => {
        return st.id == type;
      });

      const colorOrderData = colorData[0]?.TagTypeColors?.map((data) => {
        return {
          id: data.TagColor?.id,
          name: data.TagColor?.name,
          state: 0,
          batches: 1,
          tagArt: null,
          isgeneric: false,
          zid: null,
          zoneData: null,
          eid: null,
          ctgid: null,
          isComplementary: false,
          ctGroupData: null
        };
      });

      setTypecolors(colorOrderData);

      //setTypecolors(colorData[0].TagTypeColors)
    }
  }, [type, tagtypes]);

  // Handle checkbox change
  const handleCheckboxChange = (id) => {
    setTypecolors((prevColorInfo) =>
      prevColorInfo.map((color) =>
        color.id === id ? { ...color, state: color.state === 1 ? 0 : 1 } : color
      )
    );
  };

  // Handle input change for number of batches
  const handleBatchesChange = (id, value) => {
    setTypecolors((prevColorInfo) =>
      prevColorInfo.map((color) =>
        color.id === id
          ? { ...color, batches: parseInt(value, 10) || null }
          : color
      )
    );
  };

  // handle input change for color tag art
  const handleTagArtChange = (id, value) => {
    setTypecolors((prevColorInfo) =>
      prevColorInfo.map((color) =>
        color.id === id ? { ...color, tagArt: value } : color
      )
    );
  };

  // handle input change for color event
  const handleEventChange = (id, zones, eid) => {
    setTypecolors((prevColorInfo) =>
      prevColorInfo.map((color) =>
        color.id === id ? { ...color, zoneData: zones?.filter((data) => { return data?.cost > 0 }), eid } : color
      )
    );
  };



  // handle input change for color event
  const handleEventChangeComplementary = (id, ctGroupData, eid) => {
    setTypecolors((prevColorInfo) =>
      prevColorInfo.map((color) =>
        color.id === id ? { ...color, ctGroupData, eid } : color
      )
    );
  };


  // handle input change for color generic
  const handleIsGenericChange = (id) => {
    setTypecolors((prevColorInfo) =>
      prevColorInfo.map((color) =>
        color.id === id ? { ...color, isgeneric: !(color.isgeneric) } : color
      )
    );
  };

  // handle input change for complementary
  const handleComplementaryChange = (id) => {
    setTypecolors((prevColorInfo) =>
      prevColorInfo.map((color) =>
        color.id === id ? { ...color, isComplementary: !(color.isComplementary) } : color
      )
    );
  };


  // handle input change for color zone
  const handleZoneChange = (id, zid) => {
    setTypecolors((prevColorInfo) =>
      prevColorInfo.map((color) =>
        color.id === id ? { ...color, zid } : color
      )
    );
  };


  // handle input change for color ctgroup
  const handleCtgroupChange = (id, ctgid) => {
    setTypecolors((prevColorInfo) =>
      prevColorInfo.map((color) =>
        color.id === id ? { ...color, ctgid } : color
      )
    );
  };

  // handle input change for color number of tags
  const handleTagNumberChange = (id, batchNumberMode) => {
    setTypecolors((prevColorInfo) =>
      prevColorInfo.map((color) =>
        color.id === id ? { ...color, tags: batchNumberMode == 'full' ? 200 : batchNumberMode == 'half' ? 100 : 50 } : color
      )
    );
  };



  const handleSubmit = (e) => {
    e.preventDefault();

    if (!type) {
      twit("info", "Please select a tag type.");
    } else {
      purchaseTags(setLoading)
        .then(() => {
          twit("success", "Order placed successfully.");
          setRefresh(!refresh);
          setType(" ");
          setShowInvoice(false)
        })
        .catch((error) => {
          twit("info", error);
        });
    }
  };



  const downloadPDF = () => {
    const element = document.getElementById('invoice');
    html2pdf(element, {
      output: '/invoice folder',
      filename: 'invoice.pdf',
    });

    setTimeout(function () {
      setPrintable(false);
    }, 2000);
  }


  const getTypeCost = () => {
    return tagtypes.filter((data) => {
      return data.id == type
    })[0]?.bcost
  }


  const getInvoiceItems = () => {

    const price = getTypeCost() ? Number(getTypeCost()) : 0

    if (currentInvoiceData.length < 1) {
      const tagOrders = typecolors
        ?.filter((data) => {
          return data.state == 1;
        })
        .map((data) => {
          return { name: `${capitalizeFirstLetter(data.name)}- Wrist Band`, qty: Number(data?.batches) * Number(data?.tags), amount: Number(data?.batches) * Number(data?.tags) * price, unitPrice: price }
        });

      return [...tagOrders]
    } else {
      return currentInvoiceData
    }


  }





  //pagination logic
  const postsPerPage = 5
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const paginatedOrders = orders?.slice(indexOfFirstPost, indexOfLastPost)
  const paginate = (number) => {
    setCurrentPage(number);

  }

  if (loading || loadingOrders) {
    return <Loader />;
  } else {
    return (
      <Container>


        {showInvoice && <Modal open={true} onCancel={() => {
          setShowInvoice();
          setCurrentInvoiceData([])
          setInvoiceDate('')
          setInvoiceNumber('')
        }}
          footer={[


          ]}


          closable={false}
          className='allmodals'
        >



          <div id='invoice' >
            <Invoice items={getInvoiceItems()}
              printable={printable}
              deliveryCost={10000}
              setInvoiceNumberExt={setInvoiceNumber}
              invoiceNumber={invoiceNumber ? invoiceNumber : 'To be generated'}
              invoiceDate={invoiceDate ? getformattedDate(invoiceDate) : invoiceDate}
              setTotalCost={setTotalCost}
            />
          </div>


          <div>



            {(currentInvoiceData.length > 0) ? (
              <Button

                title='Download a copy.'
                onClick={() => {

                  downloadPDF();
                  setPrintable(true);

                }}

                className='identity-form-button ' variant="success" type="button">

                <span style={{ fontWeight: "bold" }}><MdIcons.MdAddShoppingCart
                  className="mx-1"
                  style={{ width: "15px", height: "15px" }}
                />{" "}
                  | Download </span>
              </Button>
            ) : (<></>)}



            {!(currentInvoiceData.length > 0) ? (
              <Button

                title='Place Order.'
                onClick={(e) => {


                  handleSubmit(e)


                }}

                className='identity-form-button ' variant="success" type="button">

                <span style={{ fontWeight: "bold" }}><MdIcons.MdAddShoppingCart
                  className="mx-1"
                  style={{ width: "15px", height: "15px" }}
                />{" "}
                  | Confirm </span>
              </Button>
            ) : (<></>)}



          </div>







        </Modal>}


        {showCropper && cropperImage && (
          <Modal
            open={true}
            title={
              "Wrist Band Image Formating (756 * 2800)"
            }
            onCancel={() => {
              setShowCropper(false);
            }}
            footer={[]}
            className="allmodals"
          >
            <div style={{ fontSize: "12px" }}>
              <Card>
                <Card.Body>
                  <Row>
                    <ImageCropper
                      image={cropperImage}
                      setImage={setCropperImage}
                      setOpen={setShowCropper}
                      setCropResultFile={
                        (imageData) => {
                          handleTagArtChange(currentColorId, imageData)
                        }
                      }
                      windowWidth={"300px"}
                      windowHeight={"300px"}
                      aspectRatio={

                        2800 / 756

                      }
                    />
                  </Row>
                </Card.Body>
              </Card>
            </div>
          </Modal>
        )}

        {showPreview && (
          <Modal
            open={true}
            title={"Tag Art Preview"}
            onCancel={() => {
              setShowPreview(false);
            }}
            footer={[]}
            className="allmodals"
          >
            <div style={{ fontSize: "12px" }}>
              <Card>
                <Card.Body>
                  <Row>
                    <div>
                      <div className="my-1 " style={{ width: "100%" }}>
                        <ImagePreview
                          selectedImage={previewImage}
                          size={"756 * 2800"}
                          containerHeight="95px"
                          containerWidth="378px"
                          containerHeightMobile="95px"
                          containerWidthMobile="278px"
                          placeholderMessage={
                            "This will appear On The Generated Wrist bands"
                          }
                        />
                      </div>
                    </div>
                  </Row>
                </Card.Body>
              </Card>
            </div>
          </Modal>
        )}

        {(showZoneLoader || showCtgroupsLoader) && (
          <TransparentOverlay message={''} />
        )}

        <Row>
          <Col>
            <p style={{ fontWeight: "bold" }}>Order Tags </p>
            <Form onSubmit={(e) => {
              e.preventDefault()
              if (typecolors
                .filter((data) => {
                  return data.state == 1;
                }).length <= 0) {
                twit('info', 'Please select atleast one item.')
              } else {
                setShowInvoice(true)
              }

            }}>
              <Card>
                <Card.Body>
                  <Row>
                    <Col className="my-1" md={12}>
                      <Form.Group controlId="tagtype">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <div className="mx-2 FormLabels">
                            <p style={{ margin: 0 }}>
                              Type<span style={{ color: "red" }}> *</span>
                            </p>
                          </div>
                          <Form.Select
                            name="tagtype"
                            onChange={(e) => {
                              setType(e.target.value);
                            }}
                            className="form-selector"
                          >
                            <option className="input-box" key={1} value={""}>
                              Select Tag Type
                            </option>
                            {tagtypes?.map((data) => {
                              return (
                                <option
                                  className="input-box"
                                  key={data.id}
                                  value={data.id}
                                >
                                  {data.name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  {typecolors?.map((data, index) => (
                    <Card className='my-2'>
                      <Card.Body>
                        <Row key={index}>
                          <Col className="my-1" md={10}>
                            <Form.Group controlId="color">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <div className="d-flex flex-row ">
                                  <Form.Check
                                    type="checkbox"
                                    label=""
                                    name="color"
                                    value={true}
                                    checked={data.state}
                                    onChange={(e) => {
                                      handleCheckboxChange(data.id);
                                    }}
                                    className="my-0 mx-1 input-box-events form-control-sm"
                                  />
                                </div>

                                <div
                                  style={{
                                    width: "80%",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {/* <div
                                    title={data.name}
                                    style={{
                                      width: "400px",
                                      height: "30px",
                                      backgroundColor: data.name,
                                      border: "1px solid #ccc",
                                      borderRadius: "5px",
                                    }}
                                  ></div> */}

                                  <p className="text-muted" style={{ fontSize: '20px' }}>{data.name}</p>

                                </div>
                              </div>

                            </Form.Group>
                          </Col>
                          <Col md={2}>
                            <img style={{ width: '95px' }} src={`${apiBaseUrl}/generalFiles/${removeSpacesAndLowerCase(data.name)}.jpg`} alt="Image description" />
                          </Col>

                          {data.state ? (
                            <hr />
                          ) :
                            ''
                          }

                          {data.state ? (
                            <>
                              <Col className="my-1" md={3}>
                                <Form.Group controlId="specific-event">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="d-flex flex-row ">
                                      <Form.Check
                                        disabled={!data.state}
                                        type="checkbox"
                                        label="For Specific Event"
                                        name="specific event"
                                        value={true}
                                        checked={!(data.isgeneric)}
                                        onChange={(e) => {
                                          handleIsGenericChange(data.id);
                                        }}
                                        className="my-0 mx-1 input-box-events form-control-sm"
                                      />
                                    </div>


                                  </div>
                                </Form.Group>
                              </Col>


                              {!(data.isgeneric) && (
                                <Col className="my-1" md={2}>
                                  <Form.Group controlId="complementary">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div className="d-flex flex-row ">
                                        <Form.Check
                                          disabled={!data.state}
                                          type="checkbox"
                                          label="Complementary"
                                          name="complementary"
                                          value={true}
                                          checked={(data.isComplementary)}
                                          onChange={(e) => {
                                            handleComplementaryChange(data.id);
                                          }}
                                          className="my-0 mx-1 input-box-events form-control-sm"
                                        />
                                      </div>


                                    </div>
                                  </Form.Group>
                                </Col>
                              )}


                              {!(data.isgeneric) && (
                                <Col className="my-1" md={7}>
                                  <Form.Group controlId="event">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div className="mx-2 FormLabels">
                                        <p style={{ margin: 0 }}>
                                          Event<span style={{ color: "red" }}> *</span>
                                        </p>
                                      </div>
                                      <Form.Select
                                        name="event"
                                        onChange={(e) => {

                                          if (!(data.isComplementary)) {
                                            fetchData(`/api/events/zones/${e.target.value}`, setShowZoneLoader).then((response) => {
                                              handleEventChange(data.id, response.data.zones, e.target.value)
                                            }).catch((error) => {
                                              twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event zones.')
                                            })
                                          }

                                          if (data.isComplementary) {
                                            fetchData(`/api/events/complementaryTicket/groups/${e.target.value}`, setShowCtgroupsLoader).then((response) => {
                                              handleEventChangeComplementary(data.id, response.data.ticketGroups, e.target.value)
                                            }).catch((error) => {
                                              twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event ctgroups.')
                                            })
                                          }


                                        }}


                                        className="form-selector"
                                      >
                                        <option className="input-box" key={1} value={""}>
                                          {data.eid
                                            ? events.filter((dataE) => dataE.eid == data.eid)[0]
                                              .name
                                            : "Select Event"}
                                        </option>
                                        {events?.map((data) => {
                                          return (
                                            <option
                                              className="input-box"
                                              key={data.eid}
                                              value={data.eid}
                                            >
                                              {data.name}
                                            </option>
                                          );
                                        })}
                                      </Form.Select>
                                    </div>
                                  </Form.Group>
                                </Col>
                              )}

                              


                              {!(data.isgeneric) &&(data.isComplementary) && (
                                <>
                                  {data.ctGroupData && (
                                    <Col className="my-1" md={6}>
                                      <Form.Group controlId="ctgroups">
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div className="mx-2 FormLabels">
                                            <p style={{ margin: 0 }}>
                                              Ticket Groups<span style={{ color: "red" }}> *</span>
                                            </p>
                                          </div>
                                          <Form.Select
                                            required
                                            name="ctgroups"
                                            onChange={(e) => {
                                              handleCtgroupChange(data.id, e.target.value)
                                            }}
                                            className="form-selector"
                                          >
                                            <option
                                              className="input-box"
                                              key={1}
                                              value={""}
                                            >
                                              Select Group
                                            </option>
                                            {data.ctGroupData?.map((data) => {
                                              return (
                                                <option
                                                  className="input-box"
                                                  key={data.id}
                                                  value={data.id}
                                                >
                                                  {data.name}
                                                </option>
                                              );
                                            })}
                                          </Form.Select>
                                        </div>
                                      </Form.Group>
                                    </Col>
                                  )}
                                </>
                              )}


                              {!(data.isgeneric) && !(data.isComplementary) && (
                                <>
                                  {data.zoneData && (
                                    <Col className="my-1" md={6}>
                                      <Form.Group controlId="zone">
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div className="mx-2 FormLabels">
                                            <p style={{ margin: 0 }}>
                                              Zone<span style={{ color: "red" }}> *</span>
                                            </p>
                                          </div>
                                          <Form.Select
                                            required
                                            name="zone"
                                            onChange={(e) => {
                                              handleZoneChange(data.id, e.target.value)
                                            }}
                                            className="form-selector"
                                          >
                                            <option
                                              className="input-box"
                                              key={1}
                                              value={""}
                                            >
                                              Select Zone
                                            </option>
                                            {data.zoneData?.map((data) => {
                                              return (
                                                <option
                                                  className="input-box"
                                                  key={data.zid}
                                                  value={data.zid}
                                                >
                                                  {data.name}
                                                </option>
                                              );
                                            })}
                                          </Form.Select>
                                        </div>
                                      </Form.Group>
                                    </Col>
                                  )}
                                </>
                              )}

                              <Col className="my-2" md={6}>
                                <Form.Group controlId="batches">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="mx-2 FormLabels">
                                      <p style={{ margin: 0 }}>
                                        Batches<span style={{ color: "red" }}> *</span>
                                      </p>
                                    </div>
                                    <Form.Control
                                      title="Number Of Batches"
                                      className=" input-box-events form-control-sm"
                                      type="number"
                                      placeholder="Enter batches"
                                      value={data.batches}
                                      min={1}
                                      disabled={!data.state}
                                      onChange={(e) => {
                                        handleBatchesChange(data.id, e.target.value);
                                      }}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                              {(data.isgeneric) && (
                                <>

                                  <Col className="my-2" md={5}>
                                    <Form.Group controlId="faceImage">
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div className="mx-2 FormLabels">
                                          <p style={{ margin: 0 }}>Tag Art {data.isgeneric ? <span style={{ color: "red" }}> *</span> : <></>}</p>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            width: "100%",
                                          }}
                                        >
                                          <div style={{ width: "100%" }}>
                                            <Form.Control
                                              required={data.isgeneric}
                                              accept=".jpeg,.jpg,.png"
                                              name="wrist-art"
                                              onClick={(e) => {
                                                e.target.value = null;
                                              }}
                                              onChange={(e) => {
                                                setCropperImage(
                                                  URL.createObjectURL(e.target.files[0])
                                                );
                                                setShowCropper(true);
                                                setCurrentColorId(data.id)
                                              }}
                                              className=" input-box-events form-control-sm"
                                              type="file"
                                            />



                                          </div>
                                        </div>
                                      </div>
                                    </Form.Group>
                                  </Col>

                                  <Col className="my-2" md={1}>
                                    <Form.Group controlId="tag-art">
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >

                                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }} >
                                          <MdIcons.MdPreview className='mx-2 ' title='View Tag Art' style={{ height: "15px", width: "15px" }} onClick={() => {
                                            setPreviewImage(data.tagArt)
                                            setShowPreview(true)
                                          }} />
                                        </div>

                                      </div>
                                    </Form.Group>
                                  </Col>
                                </>
                              )}


                              <Col md={12} style={{ minHeight: '100%' }}>
                                <div className='d-flex flex-row   w-100' style={{ alignItems: 'center' }}>
                                  <div className='mx-2 FormLabels'>
                                    <p style={{ margin: 0, }}>Batch Size </p>
                                  </div>
                                  <div className='d-flex flex-row w-100'>
                                    <Form.Check
                                      type="radio"
                                      label="Full (200)"
                                      name={`${data.id}`}
                                      value="full"
                                      onChange={(e) => handleTagNumberChange(data.id, e.target.value)}
                                      className="my-0 mx-2 input-box-events form-control-sm"
                                    />
                                    <Form.Check
                                      type="radio"
                                      label="Half (100)"
                                      name={`${data.id}`}
                                      value="half"
                                      onChange={(e) => handleTagNumberChange(data.id, e.target.value)}
                                      className="my-0 mx-2 input-box-events form-control-sm"
                                    />

                                    <Form.Check
                                      type="radio"
                                      label="Quarter (50)"
                                      name={`${data.id}`}
                                      value="quarter"
                                      onChange={(e) => handleTagNumberChange(data.id, e.target.value)}
                                      className="my-0 mx-2 input-box-events form-control-sm"
                                    />

                                  </div>


                                </div>
                              </Col>

                            </>
                          ) : <></>}


                        </Row>
                      </Card.Body>
                    </Card>
                  ))}

                  <Row>
                    <Col md={12}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className="my-2"
                      >
                        {/* <p className="text-muted" style={{ margin: 0 }}>
                          <MdIcons.MdInfoOutline
                            className="mx-1 "
                            style={{ width: "15px", height: "15px" }}
                          />{" "}
                          | Each batch contains 200 tags
                        </p> */}
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <div
                className="mt-2"
                style={{ width: "100%", textAlign: "center" }}
              >
                <Button
                  style={{ backgroundColor: "#FFFFFF", color: "black" }}
                  variant="primary"
                  type="primary"
                  htmlType="submit"
                >
                  <MdIcons.MdAddShoppingCart
                    className="mx-1"
                    style={{ width: "15px", height: "15px" }}
                  />{" "}
                  | Proceed
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
        <Row>
          <p style={{ fontWeight: "bold" }}> Tag Orders </p>
          <div className="table-responsive">
            {orders.length > 0 ? (
              <Table striped hover style={{ cursor: "pointer" }}>
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Order No.</th>
                    <th>Tag Type</th>
                    <th>Status</th>
                    <th>Batches</th>
                    <th>Placed</th>
                    <th>Operations</th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedOrders?.map((data, index) => (
                    <tr key={index} onClick={() => { }}>
                      <td>{((currentPage * 5) - postsPerPage) + (index + 1)}</td>
                      <td>{data.orderNumber}</td>
                      <td>{data.TagType.name}</td>
                      <td>{data.OrderStatus.osname}</td>
                      <td style={{ fontSize: '10px', whiteSpace: 'nowrap' }}>{data.tbatches}</td>
                      <td style={{ fontSize: '10px', whiteSpace: 'nowrap' }}>{getformattedDate(data.CreatedAt)}</td>
                      <td>
                        {true && (
                          <GrIcon.GrDownload
                            className="mx-1"
                            title="View Order Invoice"
                            style={{ height: "15px", width: "15px" }}
                            onClick={() => {
                              setCurrentInvoiceData(data.invoiceData)
                              setInvoiceNumber(data.invoice)
                              setInvoiceDate(data.CreatedAt)
                              setShowInvoice(true)
                            }}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <p>No orders made.</p>
            )}

            {orders?.length > 0 && (
              <Pagination
                postsPerPage={postsPerPage}
                totalPosts={orders?.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            )}

          </div>
        </Row>
      </Container>
    );
  }
};

export default PhysicalTagPurchase;
