import React, { useState } from 'react';
import { Form, Container, Row, Col, InputGroup, Card } from 'react-bootstrap';
import { Button, Modal } from 'antd';
import Loader from '../Loader';
import { useEffect } from 'react';
import { fetchData } from '../../Utilities/apiRequests';
import axios from '../../api/axios';
import { twit } from '../Notificationpopout';
import * as GiIcon from 'react-icons/gi';
import { getNairobiTime, isFileSizeExceed } from '../../Utilities/utilities';
import * as MdIcons from 'react-icons/md';
import ImagePreview from '../ImagePreview';
import { fileUploadBaseUrl, maxImageFileSize } from '../../Utilities/globals';
import ImageCropper from '../ImageCropper';



const CreateEventForm = () => {

  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [categories, setCategories] = useState([]);
  const [ageCategories, setAgeCategories] = useState([]);
  const [regfields, setRegfields] = useState([]);
  const [age, setAge] = useState('');
  const [selectedCategory, setSelectedCategory] = useState();
  const [subCategory, setSubCategory] = useState();
  const [selectedSubCategory, setSelectedSubCategory] = useState();
  const [bannerImage, setBannerImage] = useState(false);
  const [logoImage, setLogoImage] = useState(false);
  const [agendaImage, setAgendaImage] = useState(false);
  const [ticketPassImage, setTicketPassImage] = useState('');
  const [showSelfReg, setShowSelfReg] = useState(false);
  const [selectedRegFields, setSelectedRegFields] = useState(['Name', 'Email', 'Mobile']);
  const [initialSubCategory, setInitialSubCategory] = useState('');
  const [initialAge, setInitialAge] = useState('');
  const [initialCategory, setInitialCategory] = useState('');
  const [showCropper, setShowCropper] = useState(false);
  const [cropperImage, setCropperImage] = useState('');
  const [imageFor, setImageFor] = useState('');
  const [highlightsData, setHighlightsData] = useState([]);
  const [selectedHighlights, setSelectedHighlights] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    location: '',
    description: '',
    venue: '',
    startDate: '',
    endDate: '',
    eventSubCategory: '',
    event: '',
    eventType: true,
    inviteOnly: false,
    paid: false,
    onlineSale: false,
    gateSale: false,
    ticketVerification: false,
    selfRegistration: false,
    includePass: false,
    publicScan: false,
    includeAgenda: false,
    ticketPass: true,
    rgself: true,
    rgaided: false,
    contactSharing: false,
    attendeeVer: false,
    includeCoupon: false,
    sendPassAfterReg: false,
    passVerification: false,
    publishOnline: false,
    listingImageOnTicket: false,
    refund: true,
    eventTerms: '',
    ticketTransfer: false,
    tSponsors: false,
    acceptDonations: false,
    requiredDonation: 0,
    showDonatedAmount: false,
    donationOnScan: false,
    allowVendorTicketSell: false,
    buyerDetailsOnVendorTicketSell: false,
    allowPromoCodes: false
  });


  const bannerformData = new FormData()
  bannerformData.append("image", bannerImage);


  const uploadBanner = async (eid) => {
    const token = sessionStorage.getItem('token');

    try {



      const response = await axios.post(`${fileUploadBaseUrl}/api/events/banner/${eid}`, bannerformData, {
        headers: {
          'content-type': 'multipart/form-data'
          , Authorization: `Bearer ${token}`
        }

      });
      return Promise.resolve(response);
    } catch (error) {

      return Promise.reject(error);
    }



  }


  const logoformData = new FormData()
  logoformData.append("image", logoImage);

  const uploadLogo = async (eid) => {
    const token = sessionStorage.getItem('token');

    try {



      const response = await axios.post(`${fileUploadBaseUrl}/api/events/logo/${eid}`, logoformData, {
        headers: {
          'content-type': 'multipart/form-data'
          , Authorization: `Bearer ${token}`
        }

      });
      return Promise.resolve(response);
    } catch (error) {

      return Promise.reject(error);
    }



  }

  const agendaformData = new FormData()
  agendaformData.append("image", agendaImage);


  const uploadAgenda = async (eid) => {
    const token = sessionStorage.getItem('token');

    try {



      const response = await axios.post(`${fileUploadBaseUrl}/api/events/agenda/${eid}`, agendaformData, {
        headers: {
          'content-type': 'multipart/form-data'
          , Authorization: `Bearer ${token}`
        }

      });
      return Promise.resolve(response);
    } catch (error) {

      return Promise.reject(error);
    }



  }


  const ticketPassLogoformData = new FormData()
  ticketPassLogoformData.append("image", ticketPassImage);

  const uploadTicketPassLogo = async (eid) => {
    const token = sessionStorage.getItem('token');

    try {



      const response = await axios.post(`${fileUploadBaseUrl}/api/events/ticketpasslogo/${eid}`, ticketPassLogoformData, {
        headers: {
          'content-type': 'multipart/form-data'
          , Authorization: `Bearer ${token}`
        }

      });
      return Promise.resolve(response);
    } catch (error) {

      return Promise.reject(error);
    }



  }

  const createEvent = async (setLoading) => {
    const token = sessionStorage.getItem('token');
    let postData = {
      name: formData.title,
      eventdescription: formData.description,
      eventDate: formData.startDate,
      escid: selectedSubCategory,
      contactInfo: formData.contactInfo,
      venue: formData.venue,
      endtime: formData.endDate,
      selfReg: formData.selfRegistration,
      priv: formData.eventType,
      location: formData.location,
      age,
      includeAgenda: formData.includeAgenda,
      ticketPass: formData.ticketPass,
      rgaided: formData.rgaided,
      rgself: formData.rgself,
      regStruct: selectedRegFields.join(),
      contactSharing: formData.contactSharing,
      attendeeVer: formData.attendeeVer,
      includeCoupon: formData.includeCoupon,
      sendPassAfterReg: formData.sendPassAfterReg,
      passVerification: formData.passVerification,
      includePass: formData.includePass,
      onlineSale: formData.onlineSale,
      paid: formData.paid,
      inviteOnly: formData.inviteOnly,
      publishOnline: formData.publishOnline,
      listingImageOnTicket: formData.listingImageOnTicket,
      refund: formData.refund,
      eventTerms: formData.eventTerms,
      ticketTransfer: formData.ticketTransfer,
      tSponsors: formData.tSponsors,
      gateSale: formData.gateSale,
      acceptDonations: formData.acceptDonations,
      requiredDonation: formData.requiredDonation,
      showDonatedAmount: formData.showDonatedAmount,
      highlights: selectedHighlights.join(','),
      donationOnScan: formData.donationOnScan,
      allowVendorTicketSell: formData.allowVendorTicketSell,
      buyerDetailsOnVendorTicketSell: formData.buyerDetailsOnVendorTicketSell,
      allowPromoCodes: formData.allowPromoCodes
    };




    const createEventUrl = '/api/events/new'
    try {
      setLoading(true);
      const response = await axios.post(createEventUrl, JSON.stringify(postData), {
        headers: {
          'Content-Type': 'application/json', Authorization: `Bearer ${token}`
        }

      });

      if (response.status === 201) {

        const bannerUpload = await uploadBanner(response.data.createdEvent.eid)

        if (bannerUpload.status === 201) {

          if (ticketPassImage) {
            await uploadTicketPassLogo(response.data.createdEvent.eid)
          }


          if (formData.includeAgenda && agendaImage && logoImage) {
            const agendaUpload = await uploadAgenda(response.data.createdEvent.eid)
            if (agendaUpload.status === 201) {

              const logoUpload = await uploadLogo(response.data.createdEvent.eid)
              if (logoUpload.status === 201) {
                setLoading(false);
                return Promise.resolve('Event created successfully.')
              }

            }
          } else if (formData.includeAgenda && agendaImage && !logoImage) {
            const agendaUpload = await uploadAgenda(response.data.createdEvent.eid)
            if (agendaUpload.status === 201) {
              setLoading(false);
              return Promise.resolve('Event created successfully.')
            }
          } else if (!(formData.includeAgenda && agendaImage) && logoImage) {
            const logoUpload = await uploadLogo(response.data.createdEvent.eid)
            if (logoUpload.status === 201) {
              setLoading(false);
              return Promise.resolve('Event created successfully.')
            }
          }








          setLoading(false);
          return Promise.resolve('Event created successfully.')
        }

      }


    } catch (err) {
      setLoading(false);
      console.log("Event creation Error:", err)
      return Promise.reject(err.response?.data?.message ? err.response?.data?.message : 'An error occured while creating the event.')

    }
  };





  useEffect(() => {
    fetchData(`/api/events/management/categories`, setLoading).then((response) => {

      setCategories(response.data.categories)

    }).catch((error) => {
      twit('info', 'failed to load event categories.')
      console.log('Event category loading error error:', error.response?.data)

    })

    fetchData(`/api/events/agecategories`, setLoading).then((response) => {

      setAgeCategories(response.data.categories)

    }).catch((error) => {
      twit('info', 'failed to load age categories.')
      console.log('Event age category loading error:', error.response?.data)

    })

    fetchData(`/api/events/highlights`, setLoading1).then((response) => {

      setHighlightsData(response.data.highlights)

    }).catch((error) => {
      twit('info', 'failed to load highlights.')
      console.log('Highlights loading error:', error.response?.data)

    })


  }, [])


  useEffect(() => {
    fetchData(`/api/events/regfields`, setLoading).then((response) => {

      if ((formData.eventType)) {
        setRegfields(response.data.fields)
      } else {
        if ((formData.paid)) {
          setRegfields(response.data.fields?.filter((data) => {
            return (data.name != 'Visual')
          }))
        } else {
          setRegfields(response.data.fields)
        }

      }


    }).catch((error) => {
      twit('info', 'failed to load registration fields.')
      console.log('failed to load registration fields  error:', error.response?.data)
    })
  }, [(formData.paid), (formData.eventType)])


  useEffect(() => {
    if (selectedCategory) {
      const categoryData = categories?.filter((category) => {
        return category.id == selectedCategory
      })
      setInitialCategory(categoryData[0]?.name)
      setSubCategory(categoryData[0].eventSubCategories)

    }
  }, [selectedCategory])

  useEffect(() => {
    if (subCategory) {
      const savedCategory = subCategory?.filter((category) => {
        return category.id == selectedSubCategory
      })

      setInitialSubCategory(savedCategory[0]?.name)

    }

    if (ageCategories) {
      const savedAge = ageCategories.filter((category) => {
        return category.id == age
      })

      setInitialAge(savedAge[0]?.name)
    }



  }, [selectedSubCategory, subCategory, ageCategories, age])


  useEffect(() => {

    if (!(formData.eventType)) {
      setFormData({
        ...formData,
        ticketPass: true,
      })
    }

  }, [(formData.eventType)])


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData };
    updatedFormData[name] = value;
    setFormData(updatedFormData);
  };


  const resetForm = () => {
    setFormData({
      title: '',
      location: '',
      description: '',
      venue: '',
      startDate: '',
      endDate: '',
      eventSubCategory: '',
      event: '',
      eventType: true,
      inviteOnly: false,
      paid: false,
      onlineSale: false,
      gateSale: false,
      ticketVerification: false,
      selfRegistration: false,
      includePass: false,
      publicScan: false,
      includeAgenda: false,
      ticketPass: true,
      rgself: true,
      rgaided: false,
      contactSharing: false,
      attendeeVer: false,
      includeCoupon: false,
      sendPassAfterReg: false,
      passVerification: false,
      publishOnline: false,
      listingImageOnTicket: false,
      refund: true,
      eventTerms: '',
      ticketTransfer: false,
      tSponsors: false,
      acceptDonations: false,
      requiredDonation: 0,
      showDonatedAmount: false,
      donationOnScan: false,
      allowVendorTicketSell: false,
      buyerDetailsOnVendorTicketSell: false,
      allowPromoCodes: false
    });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    const requiredFields = ['title', 'description', 'startDate', 'endDate', 'location'];
    const missingFields = requiredFields.filter((field) => !formData[field]);




    if (missingFields.length > 0 || !bannerImage || !selectedSubCategory || (formData.startDate == formData.endDate) || (formData.startDate > formData.endDate) || ((formData.listingImageOnTicket) && (!logoImage)) || (formData.includeAgenda && (!agendaImage))) {
      if ((formData.startDate == formData.endDate) || (formData.startDate > formData.endDate)) {
        twit('info', `Invalid ending date.`);
      } else if (!bannerImage) {
        twit('info', `Please provide a banner image`);
      } else if (((formData.listingImageOnTicket) && (!logoImage))) {
        twit('info', `Please provide a listing image`);
      } else if ((formData.includeAgenda && (!agendaImage))) {
        twit('info', `Please provide the agenda image`);
      } else if (!selectedSubCategory) {
        twit('info', `Please select an event subcategory`);
      } else {
        twit('info', `Please fill out the following fields  ${missingFields.join(', ')}`);
      }
    } else {

      createEvent(setLoading).then((data) => {
        resetForm();
        setBannerImage('')
        setAgendaImage('')
        setLogoImage('')
        setTicketPassImage('')
        setSelectedHighlights([])
        twit('success', data)
      }).catch((error) => {
        twit('info', error)
      })
    }

  };




  if (loading || loading1) {
    return (<Loader />);
  } else {

    return (
      <Container>


        {showSelfReg && (formData.selfRegistration) && <Modal open={true} title={'Event Registration'} onCancel={() => {

          setShowSelfReg(false)
        }}
          footer={[


          ]}

          className="allmodals"
        >

          <div style={{ fontSize: '12px' }} >

            <Card>
              <Card.Body >
                <Row>
                  <Col className='my-1' md={12}>

                    <Form.Group className='my-2' controlId="PaidOptions">
                      <div style={{ display: "flex", flexDirection: "row", alignItems: 'start' }}>
                        <div className='d-flex flex-column   w-100'>

                          {(formData.eventType) && (
                            <Row>



                              <Col className='my-1' md={6}>
                                <Form.Check
                                  // disabled={!(formData.selfRegistration)}
                                  type="checkbox"
                                  label="Self Registration"
                                  name="self"
                                  value={true}
                                  checked={formData.rgself}
                                  onChange={(e) => setFormData({
                                    ...formData,
                                    rgself: !formData.rgself,
                                  })}
                                  className="my-0 mx-1 input-box-events form-control-sm"
                                />
                              </Col>
                              <Col className='my-1' md={6}>
                                <Form.Check
                                  // disabled={!(formData.selfRegistration)}
                                  type="checkbox"
                                  label="Aided Registration"
                                  name="aided"
                                  value={true}
                                  checked={formData.rgaided}
                                  onChange={(e) => setFormData({
                                    ...formData,
                                    rgaided: !formData.rgaided
                                  })}
                                  className="my-0 mx-1 input-box-events form-control-sm"
                                />
                              </Col>
                            </Row>
                          )}

                          {(formData.eventType) && (
                            <div className='mx-2 my-0 FormLabels w-100'>
                              <p style={{ margin: 0 }}>Select Form Fields To Include.</p>
                            </div>
                          )}
                          {(formData.eventType) && (
                            <hr className='my-0' />
                          )}


                          <Row>
                            <Col className='my-1' md={12}>
                              <Form.Check
                                disabled={!(formData.selfRegistration)}
                                type="checkbox"
                                label={'Include All'}
                                name={`regfields`}
                                checked={selectedRegFields.length == ((regfields.length) + 3)}
                                onChange={() => {
                                  if (selectedRegFields.length == ((regfields.length) + 3)) {

                                    setSelectedRegFields(['Name', 'Email', 'Mobile'])

                                  } else {
                                    setSelectedRegFields(['Name', 'Email', 'Mobile', ...regfields?.map((fields) => {
                                      return fields.name
                                    })])

                                  }
                                }}
                                className="my-0 mx-1 input-box-events form-control-sm"
                              />

                            </Col>
                            <Col className='my-1' md={3}>

                              <Form.Check
                                disabled={!(formData.selfRegistration)}
                                type="checkbox"
                                label={'Name'}
                                name={`regfield`}
                                checked={selectedRegFields.includes('Name')}
                                onChange={() => {

                                }}
                                className="my-0 mx-1 input-box-events form-control-sm"
                              />

                              <Form.Check
                                disabled={!(formData.selfRegistration)}
                                type="checkbox"
                                label={'Email'}
                                name={`regfield`}
                                checked={selectedRegFields.includes('Email')}
                                onChange={() => {

                                }}
                                className="my-0 mx-1 input-box-events form-control-sm"
                              />

                              <Form.Check
                                disabled={!(formData.selfRegistration)}
                                type="checkbox"
                                label={'ContactNo.'}
                                name={`regfield`}
                                checked={selectedRegFields.includes('Mobile')}
                                onChange={() => {

                                }}
                                className="my-0 mx-1 input-box-events form-control-sm"
                              />

                            </Col>
                            <Col className='my-1' md={3}>
                              {regfields?.slice(0, 3).map((data, index) => (
                                <Form.Check
                                  disabled={!(formData.selfRegistration)}
                                  key={index}
                                  type="checkbox"
                                  label={data.name}
                                  name={`regfield ${index}`}
                                  checked={selectedRegFields.includes(data.name)}
                                  onChange={() => {
                                    if (selectedRegFields.includes(data.name)) {
                                      const filteredData = selectedRegFields.filter((field) => {
                                        return field != data.name
                                      })

                                      setSelectedRegFields(filteredData)

                                    } else {
                                      setSelectedRegFields([...selectedRegFields, data.name])
                                    }
                                  }}
                                  className="my-0 mx-1 input-box-events form-control-sm"
                                />
                              ))}
                            </Col>
                            <Col className='my-1' md={3}>
                              {regfields?.slice(3, 6).map((data, index) => (
                                <Form.Check
                                  disabled={!(formData.selfRegistration)}
                                  key={index}
                                  type="checkbox"
                                  label={data.name}
                                  name={`regfield ${index}`}
                                  checked={selectedRegFields.includes(data.name)}
                                  onChange={() => {
                                    if (selectedRegFields.includes(data.name)) {
                                      const filteredData = selectedRegFields.filter((field) => {
                                        return field != data.name
                                      })

                                      setSelectedRegFields(filteredData)

                                    } else {
                                      setSelectedRegFields([...selectedRegFields, data.name])
                                    }
                                  }}
                                  className="my-0 mx-1 input-box-events form-control-sm"
                                />
                              ))}
                            </Col>
                            <Col className='my-1' md={3}>
                              {regfields?.slice(6, 9).map((data, index) => (
                                <Form.Check
                                  disabled={!(formData.selfRegistration)}
                                  key={index}
                                  type="checkbox"
                                  label={data.name}
                                  name={`regfield ${index}`}
                                  checked={selectedRegFields.includes(data.name)}
                                  onChange={() => {
                                    if (selectedRegFields.includes(data.name)) {
                                      const filteredData = selectedRegFields.filter((field) => {
                                        return field != data.name
                                      })

                                      setSelectedRegFields(filteredData)

                                    } else {
                                      setSelectedRegFields([...selectedRegFields, data.name])
                                    }
                                  }}
                                  className="my-0 mx-1 input-box-events form-control-sm"
                                />
                              ))}
                            </Col>
                            <Col className='my-1' md={3}>
                              {regfields?.slice(9).map((data, index) => (
                                <Form.Check
                                  disabled={!(formData.selfRegistration)}
                                  key={index}
                                  type="checkbox"
                                  label={data.name}
                                  name={`regfield ${index}`}
                                  checked={selectedRegFields.includes(data.name)}
                                  onChange={() => {
                                    if (selectedRegFields.includes(data.name)) {
                                      const filteredData = selectedRegFields.filter((field) => {
                                        return field != data.name
                                      })

                                      setSelectedRegFields(filteredData)

                                    } else {
                                      setSelectedRegFields([...selectedRegFields, data.name])
                                    }
                                  }}
                                  className="my-0 mx-1 input-box-events form-control-sm"
                                />
                              ))}
                            </Col>
                          </Row>





                        </div>
                      </div>


                    </Form.Group>

                  </Col>
                </Row>
              </Card.Body>
            </Card>

          </div>


        </Modal>}

        {showCropper && cropperImage && <Modal open={true} title={imageFor == 'banner' ? 'Banner Image Formatting (1500 * 500)' : imageFor == 'listing' ? 'Listing Image Formatting (800 * 800)' : imageFor == 'Logo' ? 'Logo Image Formatting (617 * 374)' : 'Agenda Image Formatting (595 * 842)'} onCancel={() => {
          setShowCropper(false)
        }}
          footer={[


          ]}

          className="allmodals"
        >

          <div style={{ fontSize: '12px' }} >

            <Card>
              <Card.Body >
                <Row>
                  <ImageCropper image={cropperImage} setImage={setCropperImage} setOpen={setShowCropper} setCropResultFile={imageFor == 'banner' ? setBannerImage : imageFor == 'listing' ? setLogoImage : imageFor == 'Logo' ? setTicketPassImage : setAgendaImage} windowWidth={'300px'} windowHeight={'300px'} aspectRatio={imageFor == 'banner' ? (1500 / 500) : imageFor == 'listing' ? (800 / 800) : imageFor == 'Logo' ? (617 / 374) : (595 / 842)} />
                </Row>
              </Card.Body>
            </Card>

          </div>


        </Modal>}




        <Form onSubmit={handleSubmit}>
          <Row>
            <p style={{ fontWeight: 'bold', fontSize: '13px' }}>Create Event</p>
            <Col md={6} className='my-2'>
              <Card style={{ height: '100%' }}>
                <Card.Body style={{ height: '100%' }}>
                  <Card.Header style={{ fontWeight: 'bold' }}>
                    <div className='d-flex flex-row   w-100' style={{ alignItems: 'center' }}>
                      <div className='mx-2 FormLabels'>
                        <p style={{ margin: 0 }}>Event Type </p>
                      </div>
                      <div className='d-flex flex-row   w-100' >
                        <Form.Check
                          type="radio"
                          label="Public"
                          name="eventType"
                          value={false}
                          checked={formData.eventType == false}
                          onChange={(e) => setFormData({
                            ...formData,
                            eventType: false,
                          })}
                          className="my-0 mx-2 input-box-events form-control-sm"
                        />
                        <Form.Check
                          type="radio"
                          label="Private"
                          name="eventType"
                          value={true}
                          checked={formData.eventType}
                          onChange={(e) => setFormData({
                            ...formData,
                            eventType: true,
                          })}
                          className="my-0 mx-2 input-box-events form-control-sm"
                        />
                      </div>
                    </div>
                  </Card.Header>
                  <Form.Group className='my-2' controlId="eventCategory">
                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                      <div className='mx-2 my-2 FormLabels'>
                        <p style={{ margin: 0 }}> Category
                          <span style={{ color: "red" }}> *</span></p>
                      </div>

                      <Form.Select

                        name="eventCategory"
                        onChange={(e) => {
                          setSelectedCategory(e.target.value)
                        }}
                        className='form-selector'>
                        <option className='input-box' key={1} value={''}>{initialCategory ? initialCategory : 'Select Event Category'}</option>
                        {/* <option className='input-box' key={1} value={''}>Select Event Category</option> */}
                        {categories?.map((data) => {
                          return <option className='input-box' key={data.id} value={data.id}>{data.name}</option>
                        })}


                      </Form.Select>
                    </div>



                  </Form.Group>

                  {selectedCategory && (
                    <Form.Group className='my-2' controlId="eventSubCategory">

                      <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                        <div className='mx-2 FormLabels' >
                          <p style={{ margin: 0 }}>Subcategory
                            <span style={{ color: "red" }}> *</span></p>
                        </div>

                        <Form.Select

                          name="eventSubCategory"
                          onChange={(e) => {
                            setSelectedSubCategory(e.target.value)
                          }}
                          className='form-selector'>
                          <option className='input-box' key={1} value={''}>{initialSubCategory ? initialSubCategory : 'Select Event Subcategory'}</option>
                          {/* <option className='input-box' key={1} value={''}>Select Event Subcategory</option> */}
                          {subCategory?.map((data) => {
                            return <option className='input-box' key={data.id} value={data.id}>{data.name}</option>
                          })}


                        </Form.Select>

                      </div>

                    </Form.Group>

                  )}


                  <Form.Group className='my-2' controlId="age category">

                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                      <div className='mx-2 FormLabels' >
                        <p style={{ margin: 0 }}>Age Group
                          <span style={{ color: "red" }}> *</span></p>
                      </div>

                      <Form.Select

                        name="ageCategory"
                        onChange={(e) => {
                          setAge(e.target.value)
                        }}
                        className='form-selector'>
                        <option className='input-box' key={1} value={''}>{initialAge ? initialAge : 'Select Age Group'}</option>
                        {/* <option className='input-box' key={1} value={''}>Select Age Group</option> */}
                        {ageCategories?.map((data) => {
                          return <option className='input-box' key={data.id} value={data.id}>{data.name}</option>
                        })}


                      </Form.Select>

                    </div>

                  </Form.Group>







                  {/* For Private Event */}
                  {formData.eventType && (

                    <div>

                      <Form.Group className='my-2' controlId="reqtype">
                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                          <div className='mx-2 FormLabels'>
                            <p style={{ margin: 0 }}>Access Type
                              <span style={{ color: "red" }}> *</span></p>
                          </div>

                          <Form.Select

                            name="reqtype"
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                inviteOnly: e.target.value,
                              })
                            }}
                            className='form-selector'>
                            <option className='input-box' key={1} value={''}>Select Access Type</option>
                            {[{ name: 'By Invite', id: 1 }, { name: 'Open Access', id: 0 }].map((data) => {
                              return <option className='input-box' key={data.id} value={data.id}>{data.name}</option>
                            })}


                          </Form.Select>

                        </div>

                      </Form.Group>

                      {formData.inviteOnly == 1 && (
                        <Form.Group className='my-2' controlId="include-pass">
                          <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                            <div className='mx-2 FormLabels'>
                              <p style={{ margin: 0 }}>Event Access Options </p>
                            </div>
                            <div className='d-flex flex-row   w-100'>



                              <Form.Check
                                type="radio"
                                label="Include Pass On Invitation"
                                name="pass"
                                checked={!(formData.includePass)}
                                onChange={(e) => setFormData({
                                  ...formData,
                                  includePass: !formData.includePass,
                                  selfRegistration: !(formData.includePass)
                                })}

                                className="my-0 mx-1 input-box-events form-control-sm"
                              />

                              <Form.Check
                                type="radio"
                                label="Send Pass After Registration"
                                name="pass"
                                checked={(formData.includePass)}
                                onChange={(e) => setFormData({
                                  ...formData,
                                  selfRegistration: !(formData.includePass),
                                  includePass: !(formData.includePass),
                                })}

                                className="my-0 mx-1 input-box-events form-control-sm"
                              />


                            </div>
                          </div>


                        </Form.Group>
                      )}

                      <Form.Group className='my-2' controlId="pass-verification">
                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                          {/* <div className='mx-2 FormLabels'>
                            <p style={{ margin: 0 }}>Pass Verification </p>
                          </div> */}
                          <div className='d-flex flex-row   w-100'>



                            <Form.Check
                              type="checkbox"
                              label="Enable Pass Verification"
                              name="pass"
                              checked={formData.passVerification}
                              onChange={(e) => setFormData({
                                ...formData,
                                passVerification: !formData.passVerification,
                              })}

                              className="my-0 mx-1 input-box-events form-control-sm"
                            />




                          </div>
                        </div>


                      </Form.Group>


                      <Form.Group className='my-2' controlId="agenda">
                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                          {/* <div className='mx-2 FormLabels'>
                        <p style={{ margin: 0 }}>Agenda </p>
                      </div> */}
                          <div className='d-flex flex-row   w-100'>

                            <Form.Check
                              type="checkbox"
                              label="Include Agenda"
                              name="agenda"
                              value={true}
                              checked={formData.includeAgenda}
                              onChange={(e) => setFormData({
                                ...formData,
                                includeAgenda: !formData.includeAgenda
                              })}
                              className="my-0 mx-1 input-box-events form-control-sm"
                            />


                          </div>
                        </div>


                      </Form.Group>

                    </div>


                  )}

                  {/* For Public Event */}
                  {!formData.eventType && (

                    <div>

                      <Form.Group className='my-2' controlId="payment">

                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                          <div className='mx-2 FormLabels'>
                            <p style={{ margin: 0 }}>Access Type<span style={{ color: "red" }}> *</span></p>
                          </div>
                          <Form.Select

                            name="payment"
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                paid: e.target.value
                              })
                            }}
                            className='form-selector'>
                            <option className='input-box' key={1} value={''}>{((formData.paid == 1) || (formData.paid == 0)) ? ((formData.paid == 1) ? 'Paid Event' : 'Free Access') : 'Select Event Access Type'}</option>
                            {/* <option className='input-box' key={1} value={''}>Select Event Access Type</option> */}
                            {[{ name: 'Paid Event', id: 1 }, { name: 'Free Access', id: 0 }].map((data) => {
                              return <option className='input-box' key={data.id} value={data.id}>{data.name}</option>
                            })}


                          </Form.Select>
                        </div>


                      </Form.Group>

                      {formData.paid == 1 && (
                        <Form.Group className='my-2' controlId="Online-ticket-sale">
                          <div style={{ display: "flex", flexDirection: "row", alignItems: 'start' }}>

                            <div className='mx-2 FormLabels'>
                              <p style={{ margin: 0 }}>Admission<span style={{ color: "red" }}> *</span></p>
                            </div>
                            <div className='d-flex flex-column   w-100'>
                              <Form.Check
                                type="checkbox"
                                id='online'
                                label="Online Ticket Sale"
                                name="Onlineticket"
                                checked={formData.onlineSale}
                                onChange={(e) => setFormData({
                                  ...formData,
                                  onlineSale: !formData.onlineSale,
                                })}
                                className="my-0 mx-1 input-box-events form-control-sm"
                              />
                              <Form.Check
                                type="checkbox"
                                id='pos'
                                label="POS Ticket Sale"
                                name="Gateticket"
                                checked={formData.gateSale}
                                onChange={(e) => setFormData({
                                  ...formData,
                                  gateSale: !formData.gateSale,
                                })}
                                className="my-0 mx-1 input-box-events form-control-sm"
                              />




                            </div>
                          </div>


                        </Form.Group>
                      )}



                      {!(formData.eventType) && (
                        <Form.Group className='my-2' controlId="eventPass">
                          <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                            <div className='d-flex flex-row   w-100'>



                              <Form.Check
                                type="checkbox"
                                label="Create Pass"
                                name="pass"
                                checked={(formData.includePass)}
                                onChange={(e) => setFormData({
                                  ...formData,
                                  includePass: !(formData.includePass)
                                })}

                                className="my-0 mx-1 input-box-events form-control-sm"
                              />



                            </div>
                          </div>


                        </Form.Group>

                      )}
                      {!(formData.eventType) && (
                        <Form.Group className='my-2' controlId="ticket-verification">
                          <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                            <div className='d-flex flex-row   w-100'>



                              <Form.Check
                                type="checkbox"
                                label="Enable Ticket & Pass Verification"
                                name="Ticket"
                                checked={formData.passVerification}
                                onChange={(e) => setFormData({
                                  ...formData,
                                  passVerification: !formData.passVerification,
                                })}
                                className="my-0 mx-1 input-box-events form-control-sm"
                              />



                            </div>
                          </div>


                        </Form.Group>

                      )}

                      {!(formData.eventType) && (
                        <Form.Group className='my-2' controlId="sponsor">
                          <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                            <div className='d-flex flex-row   w-100'>



                              <Form.Check
                                type="checkbox"
                                label="Show Sponsor on ticket"
                                name="Ticket"
                                checked={formData.tSponsors}
                                onChange={(e) => setFormData({
                                  ...formData,
                                  tSponsors: !formData.tSponsors,
                                })}
                                className="my-0 mx-1 input-box-events form-control-sm"
                              />



                            </div>
                          </div>


                        </Form.Group>

                      )}

                      {!(formData.eventType) && (
                        <Form.Group className='my-2' controlId="listing-image">
                          <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                            <div className='d-flex flex-row   w-100'>



                              <Form.Check
                                type="checkbox"
                                label="Listing Image On Ticket Scan "
                                name="listing image"
                                checked={(formData.listingImageOnTicket)}
                                onChange={(e) => setFormData({
                                  ...formData,
                                  listingImageOnTicket: !(formData.listingImageOnTicket),
                                })}
                                className="my-0 mx-1 input-box-events form-control-sm"
                              />



                            </div>
                          </div>


                        </Form.Group>

                      )}

                      {!(formData.eventType) && (formData.paid == 1) && (
                        <Form.Group className='my-2' controlId="refund">
                          <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                            <div className='d-flex flex-row   w-100'>



                              <Form.Check
                                type="checkbox"
                                label="Accept Ticket Refunds"
                                name="refund"
                                checked={(formData.refund)}
                                onChange={(e) => setFormData({
                                  ...formData,
                                  refund: !(formData.refund)
                                })}

                                className="my-0 mx-1 input-box-events form-control-sm"
                              />



                            </div>
                          </div>


                        </Form.Group>

                      )}

                      {!(formData.eventType) && (formData.paid == 1) && (
                        <Form.Group className='my-2' controlId="transfer">
                          <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                            <div className='d-flex flex-row   w-100'>



                              <Form.Check
                                type="checkbox"
                                label="Allow Ticket Transfer"
                                name="transfer"
                                checked={(formData.ticketTransfer)}
                                onChange={(e) => setFormData({
                                  ...formData,
                                  ticketTransfer: !(formData.ticketTransfer)
                                })}

                                className="my-0 mx-1 input-box-events form-control-sm"
                              />



                            </div>
                          </div>


                        </Form.Group>

                      )}


                    </div>



                  )}

                  {/* For both Public and Private */}
                  <Form.Group className='my-2' controlId="include-coupon">
                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                      <div className='d-flex flex-row   w-100'>



                        <Form.Check
                          type="checkbox"
                          label="Include Coupon"
                          name="coupon"
                          checked={formData.includeCoupon}
                          onChange={(e) => setFormData({
                            ...formData,
                            includeCoupon: !formData.includeCoupon,
                          })}

                          className="my-0 mx-1 input-box-events form-control-sm"
                        />




                      </div>
                    </div>


                  </Form.Group>


                  <Form.Group className='my-2' controlId="restrict-zone">
                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                      {/* <div className='mx-2 FormLabels'>
                        <p style={{ margin: 0 }}>Zone Access </p>
                      </div> */}
                      <div className='d-flex flex-row   w-100'>



                        <Form.Check
                          type="checkbox"
                          label="Restrict Zone Access"
                          name="zones"
                          checked={formData.ticketPass}
                          onChange={(e) => {
                            if ((formData.eventType)) {
                              setFormData({
                                ...formData,
                                ticketPass: !formData.ticketPass,
                              })
                            }

                          }}

                          className="my-0 mx-1 input-box-events form-control-sm"
                        />
                      </div>
                    </div>


                  </Form.Group>


                  <Form.Group className='my-2' controlId="selfreg">
                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                      <div className=' FormLabels'>
                        <Form.Check
                          // disabled={!(formData.selfRegistration)}
                          type="checkbox"
                          label="Registration"
                          name="self"
                          value={true}
                          checked={formData.selfRegistration}
                          onChange={(e) => {
                            if (!(formData.includePass) && (formData.eventType)) {
                              setFormData({
                                ...formData,
                                selfRegistration: !(formData.selfRegistration)
                              })
                            } else if (!(formData.eventType)) {
                              setFormData({
                                ...formData,
                                selfRegistration: !(formData.selfRegistration)
                              })
                            }


                          }}
                          className="my-0 mx-1 input-box-events form-control-sm"
                        />

                      </div>

                      {formData.selfRegistration && (
                        <div className='d-flex flex-row   w-100' >
                          <MdIcons.MdPreview className='mx-2' title='Configure Self Registration' style={{ height: "15px", width: "15px" }} onClick={() => {
                            setShowSelfReg(true)
                          }} />
                        </div>
                      )}

                    </div>


                  </Form.Group>

                  <Form.Group className='my-2' controlId="accept donations">
                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                      <div className='d-flex flex-row   w-100'>



                        <Form.Check
                          type="checkbox"
                          label="Accept Donations"
                          name="donations"
                          checked={formData.acceptDonations}
                          onChange={(e) => setFormData({
                            ...formData,
                            acceptDonations: !formData.acceptDonations,
                          })}

                          className="my-0 mx-1 input-box-events form-control-sm"
                        />




                      </div>
                    </div>


                  </Form.Group>

                  {(formData.acceptDonations) && (
                    <Form.Group className='my-2' controlId="show donations">
                      <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                        <div className='d-flex flex-row   w-100'>



                          <Form.Check
                            type="checkbox"
                            label="Show Collected Donations Online"
                            name="show-donations"
                            checked={formData.showDonatedAmount}
                            onChange={(e) => setFormData({
                              ...formData,
                              showDonatedAmount: !formData.showDonatedAmount,
                            })}

                            className="my-0 mx-1 input-box-events form-control-sm"
                          />




                        </div>
                      </div>


                    </Form.Group>
                  )}



                  {(formData.acceptDonations) && (
                    <Form.Group className='my-2' controlId="show donations on scan">
                      <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                        <div className='d-flex flex-row   w-100'>



                          <Form.Check
                            type="checkbox"
                            label="Donate On Ticket Scan"
                            name="show-donations-onscan"
                            checked={formData.donationOnScan}
                            onChange={(e) => setFormData({
                              ...formData,
                              donationOnScan: !formData.donationOnScan,
                            })}

                            className="my-0 mx-1 input-box-events form-control-sm"
                          />




                        </div>
                      </div>


                    </Form.Group>
                  )}


                  <Form.Group className='my-2' controlId="allowPromoCodes ">
                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                      <div className='d-flex flex-row   w-100'>



                        <Form.Check
                          type="checkbox"
                          label="Allow Promo Codes"
                          name="allowPromoCodes"
                          checked={formData.allowPromoCodes}
                          onChange={(e) => setFormData({
                            ...formData,
                            allowPromoCodes: !formData.allowPromoCodes,
                          })}

                          className="my-0 mx-1 input-box-events form-control-sm"
                        />




                      </div>
                    </div>


                  </Form.Group>

                  <Form.Group className='my-2' controlId="accept vendor ticket sells">
                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                      <div className='d-flex flex-row   w-100'>



                        <Form.Check
                          type="checkbox"
                          label="Accept Vendor Ticket Sell"
                          name="allowVendorTicketSell"
                          checked={formData.allowVendorTicketSell}
                          onChange={(e) => setFormData({
                            ...formData,
                            allowVendorTicketSell: !formData.allowVendorTicketSell,
                          })}

                          className="my-0 mx-1 input-box-events form-control-sm"
                        />




                      </div>
                    </div>


                  </Form.Group>

                  {(formData.allowVendorTicketSell) && (
                    <Form.Group className='my-2' controlId="buyer details on sell">
                      <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                        <div className='d-flex flex-row   w-100'>



                          <Form.Check
                            type="checkbox"
                            label="Require Buyer Details On Vendor Ticket Sell"
                            name="buyerDetailsOnVendorTicketSell"
                            checked={formData.buyerDetailsOnVendorTicketSell}
                            onChange={(e) => setFormData({
                              ...formData,
                              buyerDetailsOnVendorTicketSell: !formData.buyerDetailsOnVendorTicketSell,
                            })}

                            className="my-0 mx-1 input-box-events form-control-sm"
                          />




                        </div>
                      </div>


                    </Form.Group>
                  )}

                  {(((formData.inviteOnly == 1) && (formData.eventType)) || ((!formData.eventType) && (formData.paid == 1))) && (
                    <Form.Group className='my-2' controlId="publish-online">
                      <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                        <div className='d-flex flex-row   w-100'>



                          <Form.Check
                            type="checkbox"
                            label="Publish On Online Platforms"
                            name="onlinePlatforms"
                            checked={formData.publishOnline}
                            onChange={(e) => setFormData({
                              ...formData,
                              publishOnline: !(formData.publishOnline),
                            })}

                            className="my-0 mx-1 input-box-events form-control-sm"
                          />




                        </div>
                      </div>


                    </Form.Group>

                  )}

                </Card.Body>
              </Card>

            </Col>
            <Col md={6} className='my-2' >


              {/* Event Information */}

              <Card style={{ height: '100%' }}>
                <Card.Body style={{ height: '100%' }}>
                  <Card.Header style={{ fontWeight: 'bold' }}>Event Information</Card.Header>
                  <Form.Group className='my-2' controlId="title">

                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                      <div className='mx-2 FormLabels'>
                        <p style={{ margin: 0 }}>Title<span style={{ color: "red" }}> *</span></p>
                      </div>
                      <Form.Control
                        type="text"
                        name="title"
                        value={formData.title}
                        onChange={(e) => handleInputChange(e, 'title')}
                        className="my-0 input-box-events form-control-sm"
                        required
                      />
                    </div>



                  </Form.Group>

                  <Form.Group className='my-2' controlId="description">

                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                      <div className='mx-2 FormLabels'>
                        <p style={{ margin: 0 }}>Description<span style={{ color: "red" }}> *</span></p>
                      </div>
                      <Form.Control
                        type="text"
                        as="textarea"
                        name="description"
                        value={formData.description}
                        onChange={(e) => handleInputChange(e, 'description')}
                        className="my-0 input-box-events form-control-sm"
                        required
                        maxLength={100}
                      />
                    </div>



                  </Form.Group>




                  <Form.Group className='my-2' controlId="location">

                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                      <div className='mx-2 FormLabels'>
                        <p style={{ margin: 0 }}>Location<span style={{ color: "red" }}> *</span></p>
                      </div>
                      <Form.Control
                        type="text"
                        name="location"
                        value={formData.location}
                        onChange={(e) => handleInputChange(e, 'location')}
                        className="my-0 input-box-events form-control-sm"
                        required
                      />
                    </div>

                  </Form.Group>

                  <Form.Group className='my-2' controlId="venue">
                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                      <div className='mx-2 FormLabels'>
                        <p style={{ margin: 0 }}>Venue<span style={{ color: "red" }}> *</span></p>
                      </div>
                      <Form.Control
                        type="text"
                        name="venue"
                        value={formData.venue}
                        onChange={(e) => handleInputChange(e, 'venue')}
                        className="my-0 input-box-events form-control-sm"
                        required
                      />
                    </div>

                  </Form.Group>

                  {(formData.acceptDonations) && (
                    <Form.Group className='my-2' controlId="donation-amount">
                      <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                        <div className='mx-2 FormLabels'>
                          <p style={{ margin: 0 }}>Donation Target<span style={{ color: "red" }}> *</span></p>
                        </div>
                        <Form.Control
                          type="number"
                          name="requiredDonation"
                          value={formData.requiredDonation}
                          onChange={(e) => handleInputChange(e, 'requiredDonation')}
                          className="my-0 input-box-events form-control-sm"
                          required
                        />
                      </div>

                    </Form.Group>
                  )}
                  <Form.Group className='my-2' controlId="eventTerms">

                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                      <div className='mx-2 FormLabels'>
                        <p style={{ margin: 0 }}>Event Terms </p>
                      </div>
                      <Form.Control
                        type="text"
                        as="textarea"
                        name="eventTerms"
                        value={formData.eventTerms}
                        onChange={(e) => handleInputChange(e, 'eventTerms')}
                        className="my-0 input-box-events form-control-sm"
                      // required={((!(formData.refund)) && (!(formData.eventType)) && (formData.paid == 1))}
                      />
                    </div>



                  </Form.Group>

                  <Form.Group className='my-2' controlId="bannerURL">
                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                      <div className='mx-2 FormLabels'>
                        <p style={{ margin: 0 }}>Banner Image<span style={{ color: "red" }}> *</span></p>
                      </div>

                      <div style={{ display: "flex", flexDirection: "column", alignItems: 'center', width: '100%' }}>
                        <div className='my-1 image-preview-events-form' >


                          <ImagePreview selectedImage={bannerImage} size={'1500 * 500'} placeholderMessage={'This will appear at the top of your event page.'} />

                        </div>
                        <div style={{ width: '100%' }}>
                          <Form.Control

                            required
                            accept='.jpeg,.jpg,.png'
                            name="bannerImage"
                            onClick={(e) => {
                              e.target.value = null;
                            }}
                            onChange={(e) => {
                              if (!isFileSizeExceed(maxImageFileSize, [e.target.files[0]])) {
                                //setBannerImage(e.target.files[0]);
                                setImageFor('banner')
                                setCropperImage(URL.createObjectURL(e.target.files[0]));
                                setShowCropper(true)
                              } else {
                                twit('info', `Max File Size is ${maxImageFileSize}mb`)
                              }


                            }}
                            className=' input-box-events form-control-sm'

                            type="file" />
                        </div>

                      </div>

                    </div>

                  </Form.Group>
                  <Form.Group className='my-2' controlId="logoURL">
                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                      <div className='mx-2 FormLabels'>
                        <p style={{ margin: 0 }}>Listing Image{<span style={{ color: "red" }}> *</span>}</p>
                      </div>
                      <div style={{ display: "flex", flexDirection: "column", alignItems: 'center', width: '100%' }}>
                        <div className='my-1 image-preview-events-form' >


                          <ImagePreview selectedImage={logoImage} size={'800 * 800'} placeholderMessage={'This will appear on the PinnKET event listing page for public events.'} containerHeight='250px' containerWidth='250px' containerHeightMobile='150px' containerWidthMobile='150px' />

                        </div>
                        <div style={{ width: '100%' }}>
                          <Form.Control

                            required
                            accept='.jpeg,.jpg,.png'
                            name="logoImage"
                            onClick={(e) => {
                              e.target.value = null;
                            }}
                            onChange={(e) => {



                              if (!isFileSizeExceed(maxImageFileSize, [e.target.files[0]])) {
                                //setLogoImage(e.target.files[0]);
                                setImageFor('listing')
                                setCropperImage(URL.createObjectURL(e.target.files[0]));
                                setShowCropper(true)

                              } else {
                                twit('info', `Max File Size is ${maxImageFileSize}mb`)
                              }

                            }}
                            className=' input-box-events form-control-sm'

                            type="file" />
                        </div>

                      </div>

                    </div>

                  </Form.Group>
                  <Form.Group className='my-2' controlId="ticketPasslogoURL">
                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                      <div className='mx-2 FormLabels'>
                        <p style={{ margin: 0 }}>Event Logo {(formData.listingImageOnTicket) ? <span style={{ color: "red" }}> *</span> : ''}</p>
                      </div>
                      <div style={{ display: "flex", flexDirection: "column", alignItems: 'center', width: '100%' }}>
                        <div className='my-1 image-preview-events-form' >


                          <ImagePreview selectedImage={ticketPassImage} size={'617 * 374'} placeholderMessage={'This will appear on the event tickets and passes.'} />

                        </div>
                        <div style={{ width: '100%' }}>
                          <Form.Control

                            accept='.jpeg,.jpg,.png'
                            name="ticketPassImage"
                            onClick={(e) => {
                              e.target.value = null;
                            }}
                            onChange={(e) => {



                              if (!isFileSizeExceed(maxImageFileSize, [e.target.files[0]])) {
                                //setLogoImage(e.target.files[0]);
                                setImageFor('Logo')
                                setCropperImage(URL.createObjectURL(e.target.files[0]));
                                setShowCropper(true)

                              } else {
                                twit('info', `Max File Size is ${maxImageFileSize}mb`)
                              }

                            }}
                            className=' input-box-events form-control-sm'

                            type="file" />
                        </div>

                      </div>

                    </div>

                  </Form.Group>
                  {(formData.includeAgenda) && (formData.eventType) && (
                    <Form.Group className='my-2' controlId="agenda">
                      <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                        <div className='mx-2 FormLabels'>
                          <p style={{ margin: 0 }}>Agenda <span style={{ color: "red" }}> *</span></p>
                        </div>

                        <div style={{ display: "flex", flexDirection: "column", alignItems: 'center', width: '100%' }}>
                          <div className='my-1 image-preview-events-form' >


                            <ImagePreview selectedImage={agendaImage} size={'595 * 842'} placeholderMessage={'This will appear On Pass Emails.'} />

                          </div>
                          <div style={{ width: '100%' }}>
                            <Form.Control

                              accept='.jpg,.jpeg,.png'
                              name="agenda"
                              onClick={(e) => {
                                e.target.value = null;
                              }}
                              onChange={(e) => {


                                if (!isFileSizeExceed(maxImageFileSize, [e.target.files[0]])) {
                                  //setAgendaImage(e.target.files[0]);
                                  setImageFor('agenda')
                                  setCropperImage(URL.createObjectURL(e.target.files[0]));
                                  setShowCropper(true)
                                } else {
                                  twit('info', `Max File Size is ${maxImageFileSize}mb`)
                                }

                              }}
                              className=' input-box-events form-control-sm'

                              type="file" />
                          </div>

                        </div>

                      </div>

                    </Form.Group>
                  )}


                </Card.Body>
              </Card>

            </Col>
          </Row>
          <Row className='my-3'>
            {/* Event Highlights */}
            <Col md={12} className='my-2'>



              <Card style={{ height: '100%' }}>
                <Card.Body >
                  <Card.Header style={{ fontWeight: 'bold' }}>Event Highlights</Card.Header>
                  <Row>

                    {highlightsData?.map((highlight, index) => (
                      <Col md={2} className='my-1'>
                        <Form.Group className='my-0' controlId={`highlight-option-${index}`}>
                          <Form.Check
                            type="checkbox"
                            label={highlight?.name}
                            name={`highlight-option-${index}`}
                            checked={selectedHighlights.includes(highlight?.name)}
                            onChange={(e) => {
                              if (selectedHighlights.includes(highlight?.name)) {
                                setSelectedHighlights(selectedHighlights.filter(name => name != highlight?.name));
                              } else {
                                setSelectedHighlights([...selectedHighlights, highlight?.name]);
                              }
                            }}
                            className="my-0 mx-1 input-box-events form-control-sm"
                          />
                        </Form.Group>
                      </Col>
                    ))}

                  </Row>



                </Card.Body>
              </Card>

            </Col>
          </Row>
          <Row className='my-3'>


            {/* Network & sharing */}

            {formData.eventType && (
              <Col md={4} className='my-2'>



                <Card style={{ height: '100%' }}>
                  <Card.Body >
                    <Card.Header style={{ fontWeight: 'bold' }}>Networking & Sharing</Card.Header>
                    <Row>
                      <Col md={6} className='my-2'>
                        <Form.Group className='my-2' controlId="verification">
                          <Form.Check
                            type="checkbox"
                            label="Verification By Attendee"
                            name="Ticket"
                            checked={formData.attendeeVer}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                attendeeVer: !formData.attendeeVer,
                              })
                            }}
                            className="my-0 mx-1 input-box-events form-control-sm"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6} className='my-2'>

                        <Form.Group className='my-2' controlId="sharing">

                          <Form.Check
                            type="checkbox"
                            label="Contact Sharing"
                            name="sharing"
                            checked={formData.contactSharing}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                contactSharing: !formData.contactSharing,
                              })
                            }}
                            className="my-0 mx-1 input-box-events form-control-sm"
                          />

                        </Form.Group>
                      </Col>

                    </Row>



                  </Card.Body>
                </Card>

              </Col>
            )}


            {/* Event Dates */}
            <Col md={formData.eventType ? 8 : 12} className='my-2'>



              <Card style={{ height: '100%' }}>
                <Card.Body >
                  <Card.Header style={{ fontWeight: 'bold' }}>Event Dates</Card.Header>
                  <Row>
                    <Col md={6} className='my-2'>
                      <Form.Group className='my-2' controlId="eventDate">
                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                          <div className='mx-2 FormLabels'>
                            <p style={{ margin: 0 }}>Starts<span style={{ color: "red" }}> *</span></p>
                          </div>
                          <Form.Control
                            className=' input-box-events form-control-sm'
                            type="datetime-local"
                            name="startDate"
                            value={formData.startDate}
                            onChange={handleInputChange}
                            min={getNairobiTime().slice(0, 16)}
                          />
                        </div>

                      </Form.Group>
                    </Col>
                    <Col md={6} className='my-2'>

                      <Form.Group className='my-2' controlId="eventDate">

                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                          <div className='mx-2 FormLabels'>
                            <p style={{ margin: 0 }}>Ends<span style={{ color: "red" }}> *</span></p>
                          </div>
                          <Form.Control
                            className=' input-box-events form-control-sm'
                            type="datetime-local"
                            name="endDate"
                            value={formData.endDate}
                            onChange={handleInputChange}
                            min={formData.startDate.slice(0, 16)}
                          />
                        </div>

                      </Form.Group>
                    </Col>

                  </Row>



                </Card.Body>
              </Card>

            </Col>




          </Row>


          <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
            <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit'>
              <GiIcon.GiArchiveRegister className='mx-1' style={{ width: '15px', height: '15px' }} /> | Create Event
            </Button>
          </div>

        </Form>

      </Container>
    );
  }
}
export default CreateEventForm;
