import { Navigate } from "react-router-dom";
import Cookies from 'js-cookie';


const ProtectedBranchRoute = ({
    
    redirectPath = '/assetBranch/0',
    children,
  }) => {

    const loggedin = Cookies.get('Branchloggedin');

    if (loggedin) {
        return children; 
    }
    return <Navigate to={redirectPath} replace />;
    
  };

export default ProtectedBranchRoute;