import React, { useEffect, useState } from 'react';
import { Form, Container, Row, Col, Card, Table, InputGroup, ListGroup, Collapse } from 'react-bootstrap';
import Loader from '../Loader';
import { useParams } from 'react-router-dom';
import axios from '../../api/axios';
import { Button, Divider, Drawer, Modal } from 'antd';
import { twit } from '../Notificationpopout';
import * as GiIcon from 'react-icons/gi';
import * as RxIcons from 'react-icons/rx';
import * as TiIcons from 'react-icons/ti';
import * as MdIcons from 'react-icons/md';
import { fetchData } from '../../Utilities/apiRequests';
import { formatPrice, getNairobiTime, getNairobiTimeWithParam, getNumberOnly, getformattedDate } from '../../Utilities/utilities';
import { ImCross } from "react-icons/im";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { FcExpired } from "react-icons/fc";
import * as GrIcon from 'react-icons/gr';
import useGeneralState from '../../hooks/hooks/generalState';
import Pagination from '../Pagination';
import ImageCropper from '../ImageCropper';
import ImagePreview from '../ImagePreview';
import { fileUploadBaseUrl } from '../../Utilities/globals';
import BatchesPreview from '../BatchesSearch';
import ContainedLoader from '../ContainedLoader';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, Rectangle, PieChart, Pie, Cell, ResponsiveContainer, } from 'recharts';
import ColorCube from '../ColorCube';
import TicketsPreview from '../TicketSearch';
import TransparentOverlay from '../TransparentOverlay';



const BatchAssignment = () => {

    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [loadingZones, setLoadingZones] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [batches, setBatches] = useState([]);
    const [filteredBatches, setFilteredBatches] = useState([]);
    const [showBatchAssignment, setShowBatchAssignment] = useState(false);
    const [events, setEvents] = useState([]);
    const [zones, setZones] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedBatch, setSelectedBatch] = useState('');
    const [batchData, setBatchData] = useState();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [onSmallScreen, setOnSmallScreen] = useState(false);
    const [zid, setZid] = useState('');
    const [eid, setEid] = useState('');
    const [searchTerm, setSearchTerm] = useState('');



    const rid = sessionStorage.getItem('rid')



    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);


        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerWidth]);


    useEffect(() => {
        setOnSmallScreen((screenWidth < 770))
    }, [screenWidth]);




    useEffect(() => {

        fetchData(`/api/events/unassigned/batches`, setLoading).then((response) => {

            setBatches(response.data.unassignedBatches?.sort((a, b) => Number(a.DocumentTag?.tagName) - Number(b.DocumentTag?.tagName)))

        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading batches.')
        })


        fetchData(`/api/events/byrid/${rid}`, setLoading1).then((response) => {
            setEvents(response.data.Events)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading events for batch assignment')
        })

    }, [refresh])




    useEffect(() => {



        if (eid) {
            fetchData(`/api/events/zones/${eid}`, setLoadingZones).then((response) => {
                setZones(response.data.zones)
            }).catch((error) => {
                twit('info', error.response?.data?.message ? error.response?.data?.message : 'Error loading event zones.')
            })
        }



    }, [eid, refresh])


    useEffect(() => {

        if (searchTerm) {
            setFilteredBatches(batches.filter((data) => {

                const batchName = String(data?.DocumentTag?.tagName)

                return (batchName.includes(searchTerm))
            }))
        } else {
            setFilteredBatches(batches)
        }

    }, [searchTerm, batches])



    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };


    const assignBatch = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        const postData = {

            eid,
            zid,
            batch: selectedBatch
        };

        const Url = '/api/events/batch/assign'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                setLoading(false);
                return Promise.resolve('Batch Assigned Successfully.')
            }


        } catch (error) {
            setLoading(false);
            console.log("Batch Activation Error:", error)
            return Promise.reject(error.response?.data?.message ? error.response?.data?.message : 'An error occured while activating batch.')

        }
    };












    const handleShowBatchAssignmentClose = () => {

        setShowBatchAssignment(false)
        setSelectedBatch()
        setEid('')
        setZones([])
    }




    const handleBatchAssignment = (e) => {
        e.preventDefault();

        assignBatch(setLoading).then((data) => {
            twit('success', data)
            setRefresh(!refresh)
            handleShowBatchAssignmentClose()
        }).catch((error) => {
            twit('info', error)
        })

    };




    //pagination logic
    const postsPerPage = 5
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const paginatedBatches = filteredBatches?.slice(indexOfFirstPost, indexOfLastPost)
    const paginate = (number) => {
        setCurrentPage(number);
    }










    if (loading || loading1) {
        return (<Loader />);
    } else {

        return (
            <Container>

                <div style={{ width: '100%', textAlign: 'center' }}>
                    <p style={{ margin: 0, fontWeight: 'bold' }}>Batch Assignment</p>
                </div>



                {loadingZones && (
                    <TransparentOverlay message={''} />
                )}

                {showBatchAssignment && <Modal open={true} title={'Batch Assignment'} onCancel={() => {

                    handleShowBatchAssignmentClose()
                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div style={{ fontSize: '12px' }} >




                        <Row>

                            <Col className='mt-2 mb-2' md={12}>
                                <Card style={{ height: '100%' }}>
                                    <Card.Body>

                                        <div className='my-2' style={{ width: '100%', backgroundColor: batchData?.TagColor.name }}>

                                            <div style={{ width: '30%', textAlign: 'center', backgroundColor: `white`, marginLeft: '5%', padding: '5px' }}>
                                                <Card.Title style={{ fontSize: '15px', margin: '0px', fontWeight: 'bold' }}>{batchData?.DocumentTag.tagName}</Card.Title>
                                            </div>

                                        </div>

                                        <div style={{ width: '100%', textAlign: 'start' }}>
                                            <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Tickets In Batch:</span>  {formatPrice(batchData?.size)}</p>
                                        </div>



                                        <>

                                            <Divider orientation="left">Assign</Divider>

                                            {

                                                <Form onSubmit={handleBatchAssignment}>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Form.Group className='my-2' >
                                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                                                                    <div className='mx-2 my-2 FormLabels'>
                                                                        <p style={{ margin: 0 }}> Event
                                                                        </p>
                                                                    </div>

                                                                    <Form.Select

                                                                        name="Event"
                                                                        required
                                                                        onChange={(e) => {
                                                                            setEid(e.target.value)
                                                                        }}
                                                                        className='form-selector'>
                                                                        <option className='input-box' key={1} value={''}>{'Select Event'}</option>

                                                                        {events?.map((data) => {
                                                                            return <option className='input-box' key={data.eid} value={data.eid}>{data.name}</option>
                                                                        })}


                                                                    </Form.Select>
                                                                </div>



                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={12}>
                                                            <Form.Group className='my-2' >
                                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                                                                    <div className='mx-2 my-2 FormLabels'>
                                                                        <p style={{ margin: 0 }}>Event Zone
                                                                        </p>
                                                                    </div>

                                                                    <Form.Select

                                                                        name="Zone"
                                                                        required
                                                                        onChange={(e) => {
                                                                            setZid(e.target.value)
                                                                        }}
                                                                        className='form-selector'>
                                                                        <option className='input-box' key={1} value={''}>{'Select Zone'}</option>

                                                                        {zones?.map((data) => {
                                                                            return <option className='input-box' key={data.zid} value={data.zid}>{data.name}</option>
                                                                        })}


                                                                    </Form.Select>
                                                                </div>



                                                            </Form.Group>
                                                        </Col>


                                                        <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                                            <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit'  >
                                                                <MdIcons.MdOutlineAdd className='mx-1' style={{ width: '15px', height: '15px' }} /> | Assign
                                                            </Button>
                                                        </div>

                                                    </Row>
                                                </Form>

                                            }








                                        </>






                                    </Card.Body>
                                </Card>
                            </Col>

                        </Row>


                    </div>


                </Modal>}



                <Row>
                    <p className='mb-0 mt-2' style={{ fontWeight: "bold" }}> Batches </p>
                    <div style={{ width: '35%', textAlign: 'end' }}>
                        <Form.Control
                            className=' input-box-events form-control-sm my-0'
                            type="text"
                            placeholder="Search Batches..."
                            value={searchTerm}
                            onChange={handleSearch}

                        />
                    </div>
                    <div className="table-responsive">
                        {filteredBatches?.length > 0 ? (
                            <Table striped hover style={{ cursor: "pointer" }}>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Batch</th>
                                        <th>Color</th>
                                        <th>Size</th>
                                        <th>Tag Type</th>
                                        <th>Operations</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedBatches?.map((data, index) => (
                                        <tr key={index} onClick={() => { }}>
                                            <td>{((currentPage * 5) - postsPerPage) + (index + 1)}</td>
                                            <td>{data?.DocumentTag?.tagName}</td>
                                            <td><ColorCube colorCode={data?.TagColor?.name} /></td>
                                            <td>{data?.size}</td>
                                            <td>{data?.TagOrder?.TagType?.name}</td>
                                            <td>
                                                {true && (
                                                    <MdIcons.MdPreview
                                                        className="mx-1"
                                                        title="Assign To Event"
                                                        style={{ height: "15px", width: "15px" }}
                                                        onClick={() => {
                                                            setSelectedBatch(data?.DocumentTag.tagName)
                                                            setBatchData(data)
                                                            setShowBatchAssignment(true)
                                                        }}
                                                    />
                                                )}


                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        ) : (
                            <p>No Batches.</p>
                        )}

                        {filteredBatches?.length > 0 && (
                            <Pagination
                                postsPerPage={postsPerPage}
                                totalPosts={filteredBatches?.length}
                                paginate={paginate}
                                currentPage={currentPage}
                            />
                        )}

                    </div>
                </Row>
            </Container>
        );
    }

}

export default BatchAssignment;
