import React, { useEffect, useState } from 'react';
import { Form, Container, Row, Col, Card, Table, InputGroup } from 'react-bootstrap';
import Loader from '../Loader';
import { useParams } from 'react-router-dom';
import axios from '../../api/axios';
import { Button, Modal } from 'antd';
import { twit } from '../Notificationpopout';
import * as GiIcon from 'react-icons/gi';
import * as RxIcons from 'react-icons/rx';
import * as TiIcons from 'react-icons/ti';
import * as MdIcons from 'react-icons/md';
import { fetchData } from '../../Utilities/apiRequests';
import { formatPrice, getNairobiTime, getNairobiTimeWithParam, getNumberOnly, getformattedDate } from '../../Utilities/utilities';
import { ImCross } from "react-icons/im";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { FcExpired } from "react-icons/fc";
import * as GrIcon from 'react-icons/gr';
import useGeneralState from '../../hooks/hooks/generalState';
import Pagination from '../Pagination';
import PhoneInput from 'react-phone-input-2';
import PromoCodeTickets from '../PromoCodeTickets';
import ContainedLoader from '../ContainedLoader';


const PromoCodeForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [promocode, setPromoCode] = useState('');
    const [expiryDate, setExpiryDate] = useState()
    const [startDate, setStartDate] = useState()
    const [discount, setDiscount] = useState('')
    const [commission, setCommission] = useState('')
    const [maxtickets, setMaxtickets] = useState(1)
    const [active, setActive] = useState(false)
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [promocodes, setPromocodes] = useState([]);
    const [showPromoCodeUpdate, setShowPromoCodeUpdate] = useState(false);
    const [selectedPromoCode, setSelectedPromoCode] = useState();
    const [eventInfo, setEventInfo] = useState([]);
    const [includedZones, setIncludedZones] = useState([]);
    const [zones, setZones] = useState([]);
    const [selectedRzones, setSelectedRzones] = useState([]);
    const [selectedZones, setSelectedZones] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [phone, setPhone] = useState('')
    const [showTransactions, setShowTransactions] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [loadingTransactions, setLoadingTransactions] = useState(false);
    const [transactionTotals, setTransactionsTotals] = useState({});




    const { eid } = useParams();
    const generalAppState = useGeneralState();
    const userCurrency = (generalAppState?.currency)

    useEffect(() => {

        fetchData(`/api/events/promocodes/eid/${eid}`, setLoading).then((response) => {
            setPromocodes(response.data.promoCodes)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading promo codes.')
        })

        fetchData(`/api/events/details/${eid}`, setLoading).then((response) => {
            setEventInfo(response.data.eventInfo)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event details.')
        })

        fetchData(`/api/events/zones/${eid}`, setLoading).then((response) => {

            setZones(response.data.zones)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event zones.')
        })



    }, [refresh])


    useEffect(() => {

        if (showTransactions) {
            fetchData(`/api/events/promocodes/transactions/eid/pcid/${eid}/${selectedPromoCode.id}`, setLoadingTransactions).then((response) => {
                setTransactions(response.data.transactions)
                setTransactionsTotals(response.data.totals)
            }).catch((error) => {
                twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event promocode transactions.')
            })

        }

    }, [showTransactions])

    const createPromoCode = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            name,
            email,
            maxTickets: maxtickets,
            eid,
            expires: expiryDate,
            starts: startDate,
            promocode,
            discount,
            status: active,
            commission,
            phoneNumber:phone,
            zids: includedZones,
        };
        const Url = '/api/events/promocodes/create'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                setLoading(false);
                return Promise.resolve('Promo Code created successfully.')
            }


        } catch (error) {
            setLoading(false);
            console.log("Promo Code creation Error:", error)
            return Promise.reject(error.response?.data?.message ? error.response?.data?.message : 'An error occured while creating Promo Code.')

        }
    };




    const disableALLaccess = () => {
        const containsdisabledZones = zones.filter((data) => {
            return !(data.isactive)
        })

        if (containsdisabledZones.length > 0) {
            return true
        } else {
            return false
        }
    }



    const handleSubmit = (e) => {
        e.preventDefault();


        createPromoCode(setLoading).then((data) => {

            setName('')
            setPromoCode('')
            setEmail('')
            setPhone('')
            setDiscount('')
            setExpiryDate('')
            setStartDate('')
            setCommission('')
            setMaxtickets('')
            setActive(false)
            setRefresh(!refresh)
            twit('success', data)
            setIncludedZones([])
        }).catch((error) => {
            twit('info', error)
        })

    };

    const updatePromoCode = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            name: name ? name : selectedPromoCode.name,
            promocode: promocode ? promocode : selectedPromoCode.promocode,
            eid,
            expires: expiryDate ? expiryDate : selectedPromoCode.expires?.slice(0, 16),
            starts: startDate ? startDate : selectedPromoCode.starts?.slice(0, 16),
            discount: discount ? discount : selectedPromoCode.discount,
            commission: commission ? commission : selectedPromoCode.commission,
            status: active,
            maxTickets: maxtickets ? maxtickets : selectedPromoCode.maxTickets,
            phoneNumber:phone,
            pcid: selectedPromoCode.id,
            zidsToAdd: selectedZones,
            zidsToRemove: selectedRzones,
        };
        const Url = '/api/events/promocodes/update'
        try {
            setLoading(true);
            const response = await axios.patch(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                setLoading(false);
                return Promise.resolve('Promo Code updated successfully.')
            }


        } catch (error) {
            setLoading(false);
            console.log("Promo code update Error:", error)
            return Promise.reject(error.response?.data?.message ? error.response?.data?.message : 'An error occured while updating promo code.')

        }
    };




    const handleUpdateSubmit = (e) => {
        e.preventDefault();



        updatePromoCode(setLoading).then((data) => {

            setName('')
            setPromoCode('')
            setEmail('')
            setPhone('')
            setDiscount('')
            setExpiryDate('')
            setStartDate('')
            setCommission('')
            setMaxtickets('')
            setActive(false)
            setRefresh(!refresh)
            setShowPromoCodeUpdate(false)
            twit('success', data)
        }).catch((error) => {
            twit('info', error)
        })

    };

    //pagination logic
    const postsPerPage = 5
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const paginatedPromoCodes = promocodes?.slice(indexOfFirstPost, indexOfLastPost)
    const paginate = (number) => {
        setCurrentPage(number);

    }


    if (loading) {
        return (<Loader />);
    } else {

        return (
            <Container>

                <div style={{ width: '100%', textAlign: 'center' }}>
                    <p style={{ margin: 0, fontWeight: 'bold' }}>{eventInfo?.name}</p>
                </div>


                {showPromoCodeUpdate && <Modal open={true} title={'Promo Code Management'} onCancel={() => {

                    setShowPromoCodeUpdate(false)
                    setActive(false)
                    setPhone('')
                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div style={{ fontSize: '12px' }} >


                        <Row>
                            <Col>

                                <Form onSubmit={handleUpdateSubmit}>

                                    <Card>
                                        <Card.Body>
                                            <Row>

                                                <Col className='my-1' md={12}>
                                                    <Form.Group className='my-2' controlId="promoCode">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Promo Code</p>
                                                            </div>
                                                            <Form.Control
                                                                type="text"
                                                                className=' input-box-events form-control-sm'
                                                                placeholder="Enter Promo Code"
                                                                value={(promocode != '') ? promocode : selectedPromoCode.promocode}
                                                                onChange={(e) => {
                                                                    if (e.target.value !== '') {
                                                                        setPromoCode(e.target.value);
                                                                    } else {
                                                                        setPromoCode(null);
                                                                    }
                                                                }}
                                                                required
                                                            />
                                                        </div>


                                                    </Form.Group>
                                                </Col>
                                                <Col className='my-1' md={12}>
                                                    <Form.Group className='my-2' controlId="promoName">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Name</p>
                                                            </div>
                                                            <Form.Control
                                                                type="text"
                                                                className=' input-box-events form-control-sm'
                                                                placeholder="Enter name"
                                                                value={(name != '') ? name : selectedPromoCode.name}
                                                                onChange={(e) => {
                                                                    if (e.target.value !== '') {
                                                                        setName(e.target.value);
                                                                    } else {
                                                                        setName(null);
                                                                    }
                                                                }}
                                                                required
                                                            />
                                                        </div>


                                                    </Form.Group>
                                                </Col>
                                                <Col className='my-1' md={12}>
                                                    <Form.Group className='my-2' controlId="email">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Email</p>
                                                            </div>
                                                            <Form.Control

                                                                required
                                                                type="email"
                                                                className=' input-box-events form-control-sm'
                                                                placeholder="Enter email"
                                                                value={(email != '') ? email : selectedPromoCode.email}
                                                                onChange={(e) => {
                                                                    if (e.target.value !== '') {
                                                                        setEmail(e.target.value);
                                                                    } else {
                                                                        setEmail(null);
                                                                    }
                                                                }}

                                                            />
                                                        </div>


                                                    </Form.Group>
                                                </Col>
                                                <Col className='my-1' md={12}>
                                                    <Form.Group controlId="mbNo.">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Mobile No.</p>
                                                            </div>

                                                            <PhoneInput
                                                                country={'ug'}
                                                                value={phone}
                                                                localization={{ ug: 'Uganda' }}
                                                                onChange={(e) => {
                                                                    setPhone(e);

                                                                }}


                                                                disableDropdown
                                                                countryCodeEditable={false}
                                                                masks={{ ug: '... ... ...' }}

                                                                inputProps={{
                                                                    name: 'phone',
                                                                    // required: true,

                                                                }}


                                                                inputStyle={{
                                                                    width: "100%",
                                                                    height: "30px",
                                                                    fontSize: "12px"
                                                                }}

                                                            />
                                                        </div>

                                                    </Form.Group>
                                                </Col>
                                                <Col className='my-1' md={12}>
                                                    <Form.Group className='my-2' controlId="startDate">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Starts</p>
                                                            </div>
                                                            <Form.Control
                                                                className=' input-box-events form-control-sm'
                                                                type="datetime-local"
                                                                name="startDate"
                                                                value={startDate ? startDate : selectedPromoCode.starts?.slice(0, 16)}
                                                                onChange={(e) => { setStartDate(e.target.value) }}
                                                                max={eventInfo?.endtime?.slice(0, 16)}
                                                            />

                                                        </div>


                                                    </Form.Group>
                                                </Col>
                                                <Col className='my-1' md={12}>
                                                    <Form.Group className='my-2' controlId="expiryDate">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}> Expires</p>
                                                            </div>
                                                            <Form.Control
                                                                className=' input-box-events form-control-sm'
                                                                type="datetime-local"
                                                                name="expiryDate"
                                                                value={expiryDate ? expiryDate : selectedPromoCode.expires?.slice(0, 16)}
                                                                onChange={(e) => { setExpiryDate(e.target.value) }}
                                                                max={eventInfo?.endtime?.slice(0, 16)}
                                                                
                                                            />

                                                        </div>


                                                    </Form.Group>
                                                </Col>
                                                <Col className='my-1' md={12}>

                                                </Col>
                                                <Col className='my-1' md={12}>
                                                    <Form.Group className='my-2' controlId="discount">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Discount Per Ticket</p>
                                                            </div>
                                                            <InputGroup>
                                                                <Form.Control
                                                                    type="text"
                                                                    className=' input-box-events form-control-sm'
                                                                    placeholder="Enter discount per ticket"
                                                                    value={(discount != '') ? discount : selectedPromoCode.discount}
                                                                    onChange={(e) => {
                                                                        if (e.target.value !== '') {
                                                                            setDiscount(getNumberOnly(e.target.value))
                                                                        } else {
                                                                            setDiscount(null);
                                                                        }
                                                                    }}

                                                                />
                                                                <InputGroup.Text style={{ fontSize: '10px' }} >
                                                                    {userCurrency ? userCurrency : 'UGX'}
                                                                </InputGroup.Text>
                                                            </InputGroup>

                                                        </div>

                                                    </Form.Group>
                                                </Col>
                                                <Col className='my-1' md={12}>
                                                    <Form.Group className='my-2' controlId="commission">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Commission</p>
                                                            </div>
                                                            <InputGroup>
                                                                <Form.Control
                                                                    type="text"
                                                                    className=' input-box-events form-control-sm'
                                                                    placeholder="Enter Commission"
                                                                    value={(commission != '') ? commission : selectedPromoCode.commission}
                                                                    onChange={(e) => {
                                                                        if (e.target.value !== '') {
                                                                            setCommission(getNumberOnly(e.target.value))
                                                                        } else {
                                                                            setCommission(null);
                                                                        }
                                                                    }}

                                                                />
                                                                <InputGroup.Text style={{ fontSize: '10px' }} >
                                                                    {'%'}
                                                                </InputGroup.Text>
                                                            </InputGroup>

                                                        </div>

                                                    </Form.Group>
                                                </Col>
                                                <Col className='my-1' md={12}>

                                                    <Form.Group className='my-2' controlId="Max-Tickets">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Max Tickets</p>
                                                            </div>
                                                            <Form.Control
                                                                type="number"
                                                                className=' input-box-events form-control-sm'
                                                                placeholder="Enter max tickets"
                                                                value={(maxtickets != '') ? maxtickets : selectedPromoCode.maxTickets}
                                                                onChange={(e) => {
                                                                    if (e.target.value !== '') {
                                                                        setMaxtickets(e.target.value);
                                                                    } else {
                                                                        setMaxtickets(null);
                                                                    }

                                                                }}

                                                            />
                                                        </div>


                                                    </Form.Group>
                                                </Col>
                                                <Col className='my-1' md={12}>
                                                    <Form.Group controlId="access zones">
                                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0, }}>Zones</p>
                                                            </div>
                                                            <div>

                                                                <div style={{ maxWidth: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                                    <Row>
                                                                        <Col sm={3}>
                                                                            <Form.Check
                                                                                disabled={disableALLaccess()}
                                                                                type="checkbox"
                                                                                label={'All Zones'}
                                                                                name="includedZones"
                                                                                checked={selectedZones?.length == zones?.length}
                                                                                onChange={(e) => {
                                                                                    if (includedZones?.length == zones?.length) {
                                                                                        setSelectedZones([])
                                                                                        setSelectedRzones(zones?.map((zd) => {
                                                                                            return zd.zid
                                                                                        }))
                                                                                    } else {
                                                                                        setSelectedZones(zones?.map((zd) => {
                                                                                            return zd.zid
                                                                                        }))
                                                                                        setSelectedRzones([])
                                                                                    }
                                                                                }}
                                                                                className="my-0 mx-1  input-box-events form-control-sm"
                                                                            />
                                                                        </Col>



                                                                        {zones.map((data) => (

                                                                            <Col sm={3}>
                                                                                <Form.Check
                                                                                    disabled={!(data.isactive)}
                                                                                    type="checkbox"
                                                                                    label={data.name}
                                                                                    name="includedZones"
                                                                                    checked={selectedZones.includes(data.zid)}
                                                                                    onChange={(e) => {
                                                                                        if (selectedZones.includes(data.zid)) {
                                                                                            setSelectedZones(selectedZones.filter((zones) => {
                                                                                                return zones != data.zid
                                                                                            }))
                                                                                            if (!selectedRzones.includes(data.zid)) {
                                                                                                selectedRzones.push(data.zid)
                                                                                            }
                                                                                        } else {
                                                                                            setSelectedZones([...selectedZones, data.zid])
                                                                                            if (selectedRzones.includes(data.zid)) {
                                                                                                setSelectedRzones(selectedRzones.filter((zones) => {
                                                                                                    return zones != data.zid
                                                                                                }))
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                    className="my-0 mx-1  input-box-events form-control-sm"
                                                                                />
                                                                            </Col>




                                                                        ))}
                                                                    </Row>
                                                                </div>


                                                            </div>



                                                        </div>

                                                    </Form.Group>
                                                </Col>
                                                {(true) && (
                                                    <Col className='my-1' md={12}>
                                                        <Form.Group controlId="activity">
                                                            <Form.Check
                                                                type="checkbox"
                                                                label="Is active"
                                                                name="Active"
                                                                checked={active}
                                                                onChange={() => {
                                                                    setActive(!active)
                                                                }}
                                                                className="my-0 mx-1 input-box-events form-control-sm"
                                                            />

                                                        </Form.Group>
                                                    </Col>
                                                )}

                                            </Row>
                                        </Card.Body>
                                    </Card>



                                    <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                        <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit'>
                                            <GiIcon.GiArchiveRegister className='mx-1' style={{ width: '15px', height: '15px' }} /> | Update Promo Code
                                        </Button>
                                    </div>
                                </Form>
                            </Col>

                        </Row>


                    </div>


                </Modal>}


                {showTransactions && <Modal open={true} title={'Promo Code Transaction Summary.'} onCancel={() => {


                    setShowTransactions(false)
                    setTransactions([])


                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div style={{ fontSize: '12px' }} >


                        {(loadingTransactions) ?
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', minHeight: '40vh' }}>
                                <ContainedLoader />
                            </div>

                            :

                            <Row>

                                <div style={{ width: '100%', textAlign: 'center' }}>
                                    <p style={{ margin: 0, fontWeight: 'bold' }}>{selectedPromoCode?.promocode}</p>
                                </div>
                                <Col className='mt-2 mb-2' md={12}>
                                    <Card style={{ height: '100%' }}>
                                        <Card.Body>



                                            <>





                                                <Row>
                                                    <Col>


                                                        <div>
                                                            <PromoCodeTickets tickets={transactions} promoCode={selectedPromoCode?.promocode} />
                                                        </div>

                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <div style={{ width: '100%', textAlign: 'center' }}>
                                                            <p style={{ margin: 0, fontWeight: 'bold' }}>Consolidated Totals</p>
                                                        </div>
                                                        <div className='mt-2' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                                                            <ul>

                                                                <li >
                                                                    <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>Consolidated Revenue:</span> {formatPrice(transactionTotals?.revenue)} UGX</p>
                                                                </li>
                                                                <li >
                                                                    <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>Consolidated Discounts:</span> {formatPrice(transactionTotals?.discounts)} UGX</p>
                                                                </li>
                                                                <li >
                                                                    <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>Consolidated Commission:</span> {formatPrice(transactionTotals?.commission)} UGX </p>
                                                                </li>



                                                            </ul>
                                                        </div>

                                                    </Col>
                                                </Row>

                                            </>






                                        </Card.Body>
                                    </Card>
                                </Col>

                            </Row>

                        }
                    </div>


                </Modal>}




                <Row>
                    <Col>
                        <p style={{ fontWeight: 'bold' }}> Promo Code Management</p>
                        <Form onSubmit={handleSubmit}>

                            <Card>
                                <Card.Body>
                                    <Row>

                                        <Col className='my-1' md={6}>

                                            <Form.Group className='my-2' controlId="promoCode">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Promo Code</p>
                                                    </div>
                                                    <Form.Control
                                                        type="text"
                                                        maxLength={10}
                                                        className=' input-box-events form-control-sm'
                                                        placeholder="Enter Promo Code"
                                                        value={promocode}
                                                        onChange={(e) => {
                                                            setPromoCode(e.target.value);

                                                        }}
                                                        required
                                                    />
                                                </div>


                                            </Form.Group>


                                        </Col>

                                        <Col className='my-1' md={6}>


                                            <Form.Group className='my-2' controlId="promoCodeName">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Name</p>
                                                    </div>
                                                    <Form.Control
                                                        type="text"
                                                        className=' input-box-events form-control-sm'
                                                        placeholder="Enter name"
                                                        value={name}
                                                        onChange={(e) => {
                                                            setName(e.target.value);
                                                        }}
                                                        required
                                                    />
                                                </div>


                                            </Form.Group>


                                        </Col>


                                        <Col className='my-1' md={6}>


                                            <Form.Group className='my-2' controlId="promoCodeEmail">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Email</p>
                                                    </div>
                                                    <Form.Control
                                                        type="text"
                                                        className=' input-box-events form-control-sm'
                                                        placeholder="Enter Email"
                                                        value={email}
                                                        onChange={(e) => {
                                                            setEmail(e.target.value);

                                                        }}
                                                        required
                                                    />
                                                </div>


                                            </Form.Group>


                                        </Col>

                                        <Col className='my-1' md={6}>


                                            <Form.Group controlId="mbNo.">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Mobile No.</p>
                                                    </div>

                                                    <PhoneInput
                                                        country={'ug'}
                                                        value={phone}
                                                        localization={{ ug: 'Uganda' }}
                                                        onChange={(e) => {
                                                            setPhone(e);

                                                        }}


                                                        disableDropdown
                                                        countryCodeEditable={false}
                                                        masks={{ ug: '... ... ...' }}

                                                        inputProps={{
                                                            name: 'phone',
                                                            // required: true,

                                                        }}


                                                        inputStyle={{
                                                            width: "100%",
                                                            height: "30px",
                                                            fontSize: "12px"
                                                        }}

                                                    />
                                                </div>

                                            </Form.Group>


                                        </Col>



                                        <Col className='my-1' md={6}>



                                            <Form.Group className='my-2' controlId="startDate">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Starts</p>
                                                    </div>
                                                    <Form.Control
                                                        className=' input-box-events form-control-sm'
                                                        type="datetime-local"
                                                        name="startDate"
                                                        value={startDate}
                                                        onChange={(e) => { setStartDate(e.target.value) }}
                                                        max={eventInfo?.endtime?.slice(0, 16)}
                                                    />

                                                </div>


                                            </Form.Group>


                                        </Col>

                                        <Col className='my-1' md={6}>


                                            <Form.Group className='my-2' controlId="expiryDate">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}> Expires</p>
                                                    </div>
                                                    <Form.Control
                                                        className=' input-box-events form-control-sm'
                                                        type="datetime-local"
                                                        name="expiryDate"
                                                        value={expiryDate}
                                                        onChange={(e) => { setExpiryDate(e.target.value) }}
                                                        max={eventInfo?.endtime?.slice(0, 16)}
                                                    />

                                                </div>


                                            </Form.Group>


                                        </Col>

                                        <Col className='my-1' md={6}>
                                            <Form.Group className='my-2' controlId="cost">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Discount Per Ticket</p>
                                                    </div>
                                                    <InputGroup>
                                                        <Form.Control
                                                            type="text"
                                                            className=' input-box-events form-control-sm'
                                                            placeholder="Discount Per Ticket"
                                                            value={discount}
                                                            onChange={(e) => {
                                                                setDiscount(getNumberOnly(e.target.value))

                                                            }}


                                                        />
                                                        <InputGroup.Text style={{ fontSize: '10px' }} >
                                                            {userCurrency ? userCurrency : 'UGX'}
                                                        </InputGroup.Text>
                                                    </InputGroup>

                                                </div>

                                            </Form.Group>

                                        </Col>


                                        <Col className='my-1' md={6}>
                                            <Form.Group className='my-2' controlId="cost">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Commission</p>
                                                    </div>
                                                    <InputGroup>
                                                        <Form.Control
                                                            type="text"
                                                            className=' input-box-events form-control-sm'
                                                            placeholder="Commission Per Ticket"
                                                            value={commission}
                                                            onChange={(e) => {
                                                                setCommission(getNumberOnly(e.target.value))

                                                            }}


                                                        />
                                                        <InputGroup.Text style={{ fontSize: '10px' }} >
                                                            {'%'}
                                                        </InputGroup.Text>
                                                    </InputGroup>

                                                </div>

                                            </Form.Group>

                                        </Col>
                                        <Col className='my-1' md={6}>


                                            <Form.Group className='my-2' controlId="max-tickets">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Max Tickets</p>
                                                    </div>
                                                    <Form.Control
                                                        type="number"
                                                        className=' input-box-events form-control-sm'
                                                        placeholder="Enter number of tickets"
                                                        value={maxtickets}
                                                        min={1}
                                                        onChange={(e) => { setMaxtickets(getNumberOnly(e.target.value)) }}

                                                    />
                                                </div>


                                            </Form.Group>


                                        </Col>

                                        <Col className='my-1' md={12}>
                                            <Form.Group controlId="access-zones">
                                                <div className='pass-access-zones' >
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}> Zones</p>
                                                    </div>
                                                    <div style={{ maxWidth: '100%', }}>
                                                        <div className='scrollable-checkbox-div '>

                                                            <Form.Check
                                                                disabled={disableALLaccess()}
                                                                type="checkbox"
                                                                label={'All Zones'}
                                                                name="includedZones"
                                                                checked={includedZones.length == zones.length}
                                                                onChange={(e) => {
                                                                    if (includedZones.length == zones.length) {
                                                                        setIncludedZones([])
                                                                    } else {
                                                                        setIncludedZones(zones.map((zd) => {
                                                                            return zd.zid
                                                                        }))
                                                                    }
                                                                }}
                                                                className="my-0 mx-1  input-box-events form-control-sm"
                                                            />

                                                            {zones.map((data) => (

                                                                <Form.Check
                                                                    disabled={!(data.isactive)}
                                                                    type="checkbox"
                                                                    label={data.name}
                                                                    name="includedZones"
                                                                    checked={includedZones.includes(data.zid)}
                                                                    onChange={(e) => {
                                                                        if (includedZones.includes(data.zid)) {
                                                                            setIncludedZones(includedZones.filter((zones) => {
                                                                                return zones != data.zid
                                                                            }))
                                                                        } else {
                                                                            setIncludedZones([...includedZones, data.zid])
                                                                        }
                                                                    }}
                                                                    className="my-0 mx-1  input-box-events form-control-sm"
                                                                />


                                                            ))}


                                                        </div>

                                                    </div>



                                                </div>

                                            </Form.Group>
                                        </Col>

                                        {((eventInfo?.eventDate?.split('T')[0]) !== (eventInfo?.endtime?.split('T')[0])) && (
                                            <Col className='my-1' md={12}>
                                                <Form.Group controlId="renews">
                                                    <Form.Check
                                                        type="checkbox"
                                                        label="Active"
                                                        name="Active"
                                                        checked={active}
                                                        onChange={() => {
                                                            setActive(!active)
                                                        }}
                                                        className="my-0 mx-1 input-box-events form-control-sm"
                                                    />

                                                </Form.Group>
                                            </Col>
                                        )}


                                    </Row>
                                </Card.Body>
                            </Card>



                            <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit'>
                                    <GiIcon.GiArchiveRegister className='mx-1' style={{ width: '15px', height: '15px' }} /> | Add Promo Code
                                </Button>
                            </div>
                        </Form>
                    </Col>

                </Row>
                <Row>
                    <p style={{ fontWeight: 'bold' }}> Event Promo Codes </p>
                    <div className='table-responsive'>
                        {promocodes?.length > 0 ?
                            <Table striped hover style={{ cursor: "pointer" }}>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Promo Code</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Mobile</th>
                                        <th>Status</th>
                                        <th>Discount (UGX)</th>
                                        <th>Commission (%)</th>
                                        <th>Starts</th>
                                        <th>Expires</th>
                                        <th>Max Tickets</th>
                                        <th>Operations</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedPromoCodes?.map((data, index) => (
                                        <tr key={index} onClick={() => { }}>
                                            <td>{((currentPage * 5) - postsPerPage) + (index + 1)}</td>
                                            <td>{data.promocode}</td>
                                            <td>{data.name}</td>
                                            <td>{data.email}</td>
                                            <td>{data.phoneNumber?`+${data.phoneNumber}`:''}</td>
                                            <td>{!(data.status) ? <ImCross style={{ height: "15px", width: "15px", color: 'red' }} /> : <TiIcons.TiTick style={{ height: "20px", width: "20px", color: 'green' }} />}</td>
                                            <td>{data.discount}</td>
                                            <td>{data.commission}</td>
                                            <td style={{ fontSize: '10px', whiteSpace: 'nowrap' }}>{getformattedDate(data.starts)}</td>
                                            <td style={{ fontSize: '10px', whiteSpace: 'nowrap' }}>{getformattedDate(data.expires)}</td>
                                            <td>{data.maxTickets}</td>
                                            <td>


                                                <GrIcon.GrUploadOption className='mx-1' title='Update PromoCode' style={{ height: "15px", width: "15px" }} onClick={() => {
                                                    setName('')
                                                    setPromoCode('')
                                                    setEmail('')
                                                    setPhone('')
                                                    setDiscount('')
                                                    setExpiryDate('')
                                                    setStartDate('')
                                                    setCommission('')
                                                    setMaxtickets('')
                                                    setSelectedPromoCode(data)
                                                    setActive(data.status)
                                                    setPhone(data.phoneNumber)
                                                    setShowPromoCodeUpdate(true)
                                                    const promoCodeZones = data?.EventPromoCodeZones?.map((data) => {
                                                        return data.zid
                                                    })
                                                    setSelectedZones(promoCodeZones)


                                                }} />


                                                <MdIcons.MdOutlineSummarize className='mx-1' title='View transactions' style={{ height: "15px", width: "15px" }} onClick={() => {

                                                    setSelectedPromoCode(data)
                                                    setShowTransactions(true)


                                                }} />




                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            :

                            <p>No Promo Codes added.</p>

                        }
                        {promocodes?.length > 0 && (
                            <Pagination
                                postsPerPage={postsPerPage}
                                totalPosts={promocodes?.length}
                                paginate={paginate}
                                currentPage={currentPage}
                            />
                        )}

                    </div>
                </Row>
            </Container>
        );
    }

}

export default PromoCodeForm;
