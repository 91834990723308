import { Button } from 'antd';
import React, { useState } from 'react';
import { Form, Table } from 'react-bootstrap';
import { formatPrice } from '../Utilities/utilities';



const PromoCodeTickets = ({ promoCode,  tickets = [] }) => {
  const [searchTerm, setSearchTerm] = useState('');



  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };





  const filteredTickets = tickets?.filter((ticket) =>
    ticket?.tagname?.toLowerCase().includes(searchTerm.toLowerCase())
  );



  const tableStyles = {
    backgroundColor: '#f8f9fa',
    fontSize: '10px',
  };

  const headerStyles = {
    backgroundColor: '#343a40',
    color: '#fff',
  };

  const rowStyles = {
    height: '1px',
    padding: '0px',
    margin: '0px'
  };

  const rowCellStyles = {
    paddingTop: '2px',
    paddingBottom: '2px'
  }

  return (
    <div className="user-selection-component">

      <div style={{ width: '100%', textAlign: 'center' }}>
        <p style={{ margin: 0, fontWeight: 'bold' }}>{`${promoCode}`}</p>
      </div>

      <Form.Control
        className=' input-box-events form-control-sm my-2'
        type="text"
        placeholder="Search tickets..."
        value={searchTerm}
        onChange={handleSearch}

      />
      <div className='mt-2 table-responsive  user-list ' >
        <Table striped bordered hover style={tableStyles}>
          <thead>
            <tr style={headerStyles}>
              <th style={{ textAlign: "center", ...rowCellStyles }}>No.</th>
              <th style={{ ...rowCellStyles, fontWeight: 'bold' }}>Ticket</th>
              <th style={{ ...rowCellStyles, fontWeight: 'bold' }}>Discount</th>
              <th style={{ ...rowCellStyles, fontWeight: 'bold' }}>Commission</th>
              <th style={{ ...rowCellStyles, fontWeight: 'bold' }}>Amount</th>
              <th style={{ ...rowCellStyles, fontWeight: 'bold' }}>Zone</th>
              <th style={{ ...rowCellStyles, fontWeight: 'bold' }}>Date</th>
            </tr>
          </thead>
          <tbody>
            {filteredTickets?.map((item, index) => (
              <tr key={index} style={{ ...rowStyles }}>
                <td style={{ textAlign: "center", ...rowCellStyles }}>{index + 1}</td>
                <td style={{ fontWeight: 'bold', ...rowCellStyles }}>{item?.tagname}</td>
                <td style={{ fontWeight: 'bold', ...rowCellStyles }}>{ formatPrice(item?.discount)}</td>
                <td style={{ fontWeight: 'bold', ...rowCellStyles }}>{formatPrice(item?.commission)}</td>
                <td style={{ fontWeight: 'bold', ...rowCellStyles }}>{ formatPrice(item?.amount)}</td>
                <td style={{ fontWeight: 'bold', ...rowCellStyles }}>{item?.EventZone?.name}</td>
                <td style={{ fontWeight: 'bold', ...rowCellStyles }}>{item?.createdAt}</td>
              </tr>
            ))}

          </tbody>
        </Table>
      </div>



      <style jsx>{`
        .user-selection-component {
          font-family: Arial, sans-serif;
          max-width: 500px;
          margin: 0 auto;
          padding: 0px;
          
        }
        .search-input {
          width: 100%;
          padding: 10px;
          margin-bottom: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
        .user-list {
          
          max-height:200px;
          overflow-y: scroll;
        }
        .user-item {
          margin-bottom: 10px;
        }
        .user-label {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        .user-checkbox {
          margin-right: 10px;
        }
        .user-image {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 10px;
        }
        .user-name {
          font-size: 14px;
        }
        .submit-button {
          width: 100%;
          padding: 10px;
          background-color: #007bff;
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-size: 16px;
          margin-top: 10px;
        }
        .submit-button:hover {
          background-color: #0056b3;
        }
        .email-toggle {
          margin-top: 10px;
          margin-bottom: 10px;
        }
        .email-toggle label {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        .email-toggle input {
          margin-right: 10px;
        }
      `}</style>
    </div>
  );
};

export default PromoCodeTickets;