import { Navigate } from "react-router-dom";
import Cookies from 'js-cookie';


const ProtectedRoute = ({

    redirectPath = '/login',
    children,
  }) => {

    const loggedin = Cookies.get('loggedin');

    if (loggedin) {
        return children; 
    }
    return <Navigate to={redirectPath} replace />;
    
  };

export default ProtectedRoute;