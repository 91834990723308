import React, { useEffect, useState } from 'react'
import { Container, Card, Col, Row } from 'react-bootstrap';
import * as RiIcons from 'react-icons/ri';
import * as TbIcons from 'react-icons/tb';
import * as GiIcons from 'react-icons/gi';
import * as BsIcons from 'react-icons/bs';
import * as GrIcons from 'react-icons/gr';
import * as MdIcons from 'react-icons/md';
import * as HiIcons from 'react-icons/hi';
import * as FaIcons from 'react-icons/fa';
import * as CgIcons from 'react-icons/cg';
import { useNavigate } from 'react-router-dom';
import { fetchData } from '../../Utilities/apiRequests';
import Loader from '../Loader';
import { twit } from '../Notificationpopout';

const VehicleManagementHome = () => {

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const categories = [
        {
            title: 'Vehicles',
            icon: <CgIcons.CgEventbrite title='Manage Vehicles' style={{ height: "30px", width: "30px" }} />,
            link: '#',
            clickFunction: () => { navigate('#') }
        },

        {
            title: 'Vehicle layout',
            icon: <BsIcons.BsPass title='Manage Vehicle Layout' style={{ height: "30px", width: "30px" }} />,
            link: '#',
            clickFunction: () => { navigate('#') }
        },
        {
            title: 'Scheduling',
            icon: <RiIcons.RiCoupon2Fill title='Manage Vehicle Scheduling' style={{ height: "30px", width: "30px" }} />,
            link: '#',
            clickFunction: () => { navigate('#') }
        },
        {
            title: 'Routes',
            icon: <BsIcons.BsTicketPerforated title='Manage Vehicle Routes' style={{ height: "30px", width: "30px" }} />,
            link: '#',
            clickFunction: () => { navigate('#') }
        },
        {
            title: 'Bookings',
            icon: <BsIcons.BsTicketPerforated title='Manage Vehicle Bookings' style={{ height: "30px", width: "30px" }} />,
            link: '#',
            clickFunction: () => { navigate('#') }
        },
        {
            title: 'Drivers',
            icon: <BsIcons.BsTicketPerforated title='Manage Vehicle Drivers' style={{ height: "30px", width: "30px" }} />,
            link: '#',
            clickFunction: () => { navigate('#') }
        },
        {
            title: 'Reports',
            icon: <BsIcons.BsTicketPerforated title='Manage Vehicle Reports' style={{ height: "30px", width: "30px" }} />,
            link: '#',
            clickFunction: () => { navigate('#') }
        },

    ];

    if (loading) {
        return (<Loader />);
    } else {

        return (
            <div>
                <Row className='mt-2'>

                    {categories.map((category, index) => (

                        <Col md={3}>
                            <Card key={index} className='document-card'
                                onClick={category.clickFunction}
                            >
                                <Card.Body style={{ textAlign: "center" }}>
                                    <div>
                                        {category.icon}
                                    </div>
                                    <Card.Text>
                                        {category.title}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}

                </Row>

            </div>
        )
    }

}

export default VehicleManagementHome;
