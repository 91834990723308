import React, { useEffect, useRef, useState } from 'react';
import { Form, Container, Row, Col, Table, Card, InputGroup } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Loader from '../Loader';
import { Button, Divider, Modal } from 'antd';
import { twit } from '../Notificationpopout';
import axios from '../../api/axios';
import { convertFileToDataURL, downloadImage, downloadPDF, getNairobiTime, getformattedDate, isFileSizeExceed, removeSpacesInString, simulateLoading, splitArrayIntoChunks, threeDigits } from '../../Utilities/utilities';
import { fetchData } from '../../Utilities/apiRequests';
import * as MdIcons from 'react-icons/md';
import * as AiIcons from 'react-icons/ai';
import * as TiIcons from 'react-icons/ti';
import * as HiIcons from 'react-icons/hi';
import * as GiIcon from 'react-icons/gi';
import * as GrIcon from 'react-icons/gr';
import * as BsIcons from 'react-icons/bs';
import { ImCross } from "react-icons/im";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { FcExpired } from "react-icons/fc";
import visualPlacer from '../images/imagePlaceholder.png'
import { ReactMultiEmail } from 'react-multi-email';
import io from 'socket.io-client';
import { apiBaseUrl, fileUploadBaseUrl } from '../../Utilities/globals';
import PassPage from '../PassA4';
import ColorCube from '../ColorCube';
import ImagePreview from '../ImagePreview';
import ImageCropper from '../ImageCropper';
import Pagination from '../Pagination';
import PhoneInput from 'react-phone-input-2';
import VendorTransactionSearch from '../VendorTransactionSearch';
import ContainedLoader from '../ContainedLoader';
import CompTicketSearch from '../CompTicketSearch';
import demoDimensions from '../../images/complementaryTicketDimensions.jpg'

const ComplementaryTickets = () => {


    const [name, setName] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [ctgroups, setCtgroups] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingGroupData, setLoadingGroupData] = useState(false);
    const [groupRowData, setGroupRowData] = useState();
    const [updateGroupInfo, setUpdateGroupInfo] = useState();
    const [socket, setSocket] = useState(null);
    const [email, setEmail] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [isActive, setIsActive] = useState(false);
    const [showSendMails, setShowSendMails] = useState();
    const [tickets, setTickets] = useState(1);
    const [viewTickets, setViewTickets] = useState();
    const [eventInfo, setEventInfo] = useState([]);
    const [showCropper, setShowCropper] = useState(false);
    const [cropperImage, setCropperImage] = useState('');
    const [groupImage, setGroupImage] = useState('');
    const [groupImageLink, setGroupImageLink] = useState('');
    const [showTemplateUpload, setShowTemplateUpload] = useState(false);
    const [selectedCtgid, setSelectedCtgid] = useState('');
    const [starts, setStarts] = useState('');
    const [ends, setEnds] = useState('');


    const { eid } = useParams();
    const inputRef = useRef(null);
    const maxImageFileSize = 0.25

    useEffect(() => {

        fetchData(`/api/events/complementaryTicket/groups/${eid}`, setLoadingGroupData).then((response) => {
            setCtgroups(response.data.ticketGroups)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading groups.')
        })

        fetchData(`/api/events/details/${eid}`, setLoading).then((response) => {
            setEventInfo(response.data.eventInfo)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event details.')
        })

    }, [refresh])


    useEffect(() => {

        const newSocket = io(apiBaseUrl, { transports: ['websocket', 'polling'] });
        setSocket(newSocket);

    }, []);



    useEffect(() => {
        if (showSendMails) {

            inputRef?.current?.focus();

        }

    }, [showSendMails])




    const createTicketGroup = async (setLoading) => {
        const token = sessionStorage.getItem('token');



        const postData = {
            name,
            email,
            eid,
            starts,
            ends
        };

        const Url = '/api/events/complementaryTicket/group/create'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {

                setLoading(false);
                return Promise.resolve('Email group created successfully.')

            }


        } catch (err) {
            setLoading(false);
            console.log(" Email Group creation  Error:", err)
            return Promise.reject(err.response.data.message ? err.response.data.message : 'An error occured while creating emailGroup.')

        }
    };


    const createNewTickets = async (setLoading) => {
        const token = sessionStorage.getItem('token');



        const postData = {
            numberOftickets: tickets,
            ctgid: groupRowData.id,
        };

        const Url = '/api/events/complementaryTicket/create'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {

                setLoading(false);
                return Promise.resolve(response.data.message ? response.data.message : 'Tickets created successfully and will be sent shortly')

            }


        } catch (err) {
            setLoading(false);
            console.log(" New tickets creation  Error:", err)
            return Promise.reject(err.response.data.message ? err.response.data.message : 'An error occured while creating new tickets.')

        }
    };

    const updateTicketGroup = async (setLoading) => {
        const token = sessionStorage.getItem('token');


        const postData = {
            name: name ? name : groupRowData.name,
            ctgid: groupRowData.id,
            status: isActive,
            email: email ? email : groupRowData.email,
            starts:starts? starts : groupRowData.starts?.slice(0, 16),
            ends:ends? ends : groupRowData.ends?.slice(0, 16)
        };

        const Url = '/api/events/complementaryTicket/group/update'
        try {
            setLoading(true);
            const response = await axios.patch(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {

                setLoading(false);
                return Promise.resolve('Email group updated successfully.')

            }


        } catch (err) {
            setLoading(false);
            console.log("Email group update Error:", err.response?.message)
            return Promise.reject(err.response?.data?.message ? err.response?.data?.message : 'An error occured while updating email group.')

        }
    };


    const handleGroupSubmit = (e) => {
        e.preventDefault();

        if (!name || !email) {
            twit('info', 'Please provide the name and email.')
        } else {
            createTicketGroup(setLoading).then((data) => {
                twit('success', data)
                setRefresh(!refresh)
                setName('');
                setEmail('');
                setStarts('');
                setEnds('');

            }).catch((error) => {
                twit('info', error)
            })

        }

    };


    const handleNewTicketsSubmit = (e) => {
        e.preventDefault();


        createNewTickets(setLoading).then((data) => {
            twit('success', data, 5)
            setRefresh(!refresh)
            setTickets('');
            setShowSendMails(false)
            resetInputsState()
            

        }).catch((error) => {
            twit('info', error)
        })



    };


    const handleGroupSubmitUpdate = (e) => {
        e.preventDefault();

        updateTicketGroup(setLoading).then((data) => {
            twit('success', data)
            setRefresh(!refresh)
            setName('');
            setEmail('');
            setUpdateGroupInfo(false)
            setIsActive(false)
            setStarts('')
            setEnds('')
        }).catch((error) => {
            twit('info', error)
        })

    };


    const resetInputsState = () => {
        setName('');
        setEmail('');
        setUpdateGroupInfo(false)
        setIsActive(false)
    }





    //pagination logic
    const postsPerPage = 5
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const paginatedGroups = ctgroups?.sort((a, b) => a.name.localeCompare(b.name))?.slice(indexOfFirstPost, indexOfLastPost)
    const paginate = (number) => {
        setCurrentPage(number);
    }

    const templateformData = new FormData()
    templateformData.append("image", groupImage);

    const uploadTemplate = async (ctgid) => {
        const token = sessionStorage.getItem('token');

        try {


            setLoading(true)

            const response = await axios.post(`${fileUploadBaseUrl}/api/events/complementaryTicket/template/upload/${ctgid}`, templateformData, {
                headers: {
                    'content-type': 'multipart/form-data'
                    , Authorization: `Bearer ${token}`
                }

            });
            setLoading(false)
            return Promise.resolve(response);
        } catch (error) {

            return Promise.reject(error);
        }



    }


    const handleSubmit = (e) => {
        e.preventDefault();

        uploadTemplate(selectedCtgid).then((data) => {

            if (data.status === 201) {
                setGroupImage()
                setGroupImageLink('')
                twit('success', 'Template uploaded successfully.')
            }

        }).catch((error) => {
            console.log(`Email Group Template Upload Error:${error}`)
            twit('info', 'Failed to upload template')
        })

    };


    if (loading || loadingGroupData) {
        return (<Loader />);
    } else {

        return (
            <Container>


                <div style={{ width: '100%', textAlign: 'center' }}>
                    <p style={{ margin: 0, fontWeight: 'bold' }}>{eventInfo?.name}</p>
                </div>

                {showTemplateUpload && <Modal open={true} title={'Ticket Template'} onCancel={() => {
                    setShowTemplateUpload(false)
                    setGroupImageLink('')
                    setGroupImage()
                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div style={{ fontSize: '12px' }} >


                        <Row>
                            <Col>

                                <Form onSubmit={handleSubmit}>

                                    <Card>
                                        <Card.Body>
                                            <Row>

                                                <Col className='my-1' md={12}>
                                                    <Form.Group className='my-2' controlId="logoImage">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                                                            <div style={{ display: "flex", flexDirection: "column", alignItems: 'center', width: '100%' }}>
                                                                <div className='mx-2' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                                    <p style={{ margin: 0 }}>Email Group Ticket Template</p>
                                                                </div>
                                                                <div>
                                                                    <div className='my-1 ' style={{ width: '100%', }} >


                                                                        <ImagePreview selectedImage={null} size={'600 * 600'} containerHeight='110px' containerWidth='290px' containerHeightMobile='79px' containerWidthMobile='200px' placeholderMessage={'Complementary Ticket Template.'} imagelink={demoDimensions} />

                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className='my-1 ' style={{ width: '100%', }} >


                                                                        <ImagePreview selectedImage={groupImage} size={'870 * 330'} containerHeight='110px' containerWidth='290px' containerHeightMobile='79px' containerWidthMobile='200px' placeholderMessage={'Complementary Ticket Template.'} imagelink={groupImageLink} />

                                                                    </div>
                                                                </div>
                                                                <div style={{ width: '100%' }}>
                                                                    <Form.Control
                                                                        required
                                                                        accept='.jpeg,.jpg,.png'
                                                                        name="template"
                                                                        onChange={(e) => {

                                                                            if (!isFileSizeExceed(maxImageFileSize, [e.target.files[0]])) {
                                                                                setCropperImage(URL.createObjectURL(e.target.files[0]));
                                                                                setShowCropper(true)
                                                                            } else {
                                                                                twit('info', `Max File Size is ${maxImageFileSize}mb`)
                                                                            }

                                                                        }}
                                                                        className=' input-box-events form-control-sm'

                                                                        type="file" />
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </Form.Group>
                                                </Col>


                                            </Row>
                                        </Card.Body>
                                    </Card>

                                    <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                        <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit'>
                                            <GrIcon.GrUpload className='mx-1' style={{ width: '15px', height: '15px' }} /> | Upload Template
                                        </Button>
                                    </div>
                                </Form>
                            </Col>

                        </Row>


                    </div>


                </Modal>}

                {showCropper && cropperImage && <Modal open={true} title={`Template Image Formating (870 * 330)`} onCancel={() => {
                    setShowCropper(false)
                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div style={{ fontSize: '12px' }} >

                        <Card>
                            <Card.Body >
                                <Row>
                                    <ImageCropper image={cropperImage} setImage={setCropperImage} setOpen={setShowCropper} setCropResultFile={setGroupImage} windowWidth={'300px'} windowHeight={'300px'} aspectRatio={(870 / 330)} />
                                </Row>
                            </Card.Body>
                        </Card>

                    </div>


                </Modal>}

                {viewTickets && <Modal open={true} title={''} onCancel={() => {

                    setViewTickets(false)
                    resetInputsState()

                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div style={{ fontSize: '12px' }} >


                        {false ?
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', minHeight: '40vh' }}>
                                <ContainedLoader />
                            </div>

                            :

                            <Row>


                                <Col className='mt-2 mb-2' md={12}>
                                    <Card style={{ height: '100%' }}>
                                        <Card.Body>



                                            <>

                                                <Divider orientation="left">Email Group Tickets</Divider>






                                                <Row>
                                                    <Col>


                                                        <div>
                                                            <CompTicketSearch tickets={groupRowData?.ComplementaryTickets} email={groupRowData?.email} />
                                                        </div>

                                                    </Col>
                                                </Row>



                                            </>






                                        </Card.Body>
                                    </Card>
                                </Col>

                            </Row>

                        }
                    </div>


                </Modal>}

                {showSendMails && <Modal open={true} title={'New Tickets'} onCancel={() => {
                    setShowSendMails(false)
                    resetInputsState()
                }}
                    footer={[


                    ]}

                    className="allmodals"
                >
                    <Form onSubmit={handleNewTicketsSubmit}>

                        <Card>
                            <Card.Body >

                                <Row>
                                    <Col className='my-1' md={12}>
                                        <Form.Group controlId="email">

                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                <div className='mx-2 FormLabels'>
                                                    <p style={{ margin: 0 }}>Email</p>
                                                </div>
                                                <InputGroup>
                                                    <Form.Control
                                                        disabled
                                                        type="email"
                                                        className=' input-box-events form-control-sm'
                                                        placeholder="Enter email"
                                                        value={email}

                                                    />

                                                </InputGroup>

                                            </div>


                                        </Form.Group>
                                    </Col>
                                    <Col className='my-1' md={12}>
                                        <Form.Group controlId="tickets">

                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                <div className='mx-2 FormLabels'>
                                                    <p style={{ margin: 0 }}>Tickets</p>
                                                </div>
                                                <InputGroup>
                                                    <Form.Control
                                                        ref={inputRef}
                                                        type="number"
                                                        className=' input-box-events form-control-sm'
                                                        placeholder="Enter number of tickets"
                                                        value={tickets}
                                                        onChange={(e) => { setTickets(e.target.value) }}
                                                        required
                                                        min={1}
                                                        max={500}

                                                    />

                                                </InputGroup>

                                            </div>


                                        </Form.Group>
                                    </Col>

                                </Row>


                            </Card.Body>
                        </Card>



                        <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                            <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit' >
                                <GiIcon.GiArchiveRegister className='mx-1' style={{ width: '15px', height: '15px' }} /> | Submit
                            </Button>
                        </div>
                    </Form>




                </Modal>}

                {updateGroupInfo && <Modal open={true} title={'Email Group Management'} onCancel={() => {

                    resetInputsState()
                    setUpdateGroupInfo(false)

                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div style={{ fontSize: '12px' }} >



                        <Form onSubmit={handleGroupSubmitUpdate}>

                            <Card>
                                <Card.Body >



                                    <Row>




                                        <Col className='my-1' md={12}>
                                            <Row>


                                                <Col className='my-1' md={12}>
                                                    <Form.Group controlId="name">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Name<span style={{ color: "red" }}> *</span></p>
                                                            </div>

                                                            <Form.Control
                                                                className=' input-box-events form-control-sm'
                                                                type="text"
                                                                required
                                                                placeholder="Enter name"
                                                                value={(name != '' ? name : groupRowData?.name)}
                                                                onChange={(e) => {

                                                                    if (e.target.value !== '') {
                                                                        setName(e.target.value)
                                                                    } else {
                                                                        setName(null)
                                                                    }
                                                                }}

                                                            />
                                                        </div>

                                                    </Form.Group>
                                                </Col>

                                                <Col className='my-1' md={12}>
                                                    <Form.Group controlId="email">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Email<span style={{ color: "red" }}> *</span></p>
                                                            </div>

                                                            <Form.Control
                                                                disabled={groupRowData?.ComplementaryTickets.length > 0}
                                                                className=' input-box-events form-control-sm'
                                                                type="email"
                                                                required
                                                                placeholder="Enter email"
                                                                value={email}
                                                                onChange={(e) => {
                                                                    setEmail(e.target.value)
                                                                }
                                                                }

                                                            />
                                                        </div>

                                                    </Form.Group>
                                                </Col>


                                                <Col className='my-1' md={12}>


                                                    <Form.Group className='my-2' controlId="starts">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Starts</p>
                                                            </div>
                                                            <Form.Control
                                                                className=' input-box-events form-control-sm'
                                                                type="datetime-local"
                                                                name="starts"
                                                                value={starts ? starts : groupRowData.starts?.slice(0, 16)}
                                                                onChange={(e) => { setStarts(e.target.value) }}
                                                                max={eventInfo?.endtime?.slice(0, 16)}
                                                                min={eventInfo?.eventDate?.slice(0, 16)}
                                                            />

                                                        </div>


                                                    </Form.Group>


                                                </Col>

                                                <Col className='my-1' md={12}>


                                                    <Form.Group className='my-2' controlId="end">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}> Ends </p>
                                                            </div>
                                                            <Form.Control
                                                                className=' input-box-events form-control-sm'
                                                                type="datetime-local"
                                                                name="ends"
                                                                value={ends ? ends : groupRowData.ends?.slice(0, 16)}
                                                                onChange={(e) => { setEnds(e.target.value) }}
                                                                max={eventInfo?.endtime?.slice(0, 16)}
                                                            />

                                                        </div>


                                                    </Form.Group>


                                                </Col>





                                                <Col className='my-1' md={12} >

                                                    <Form.Group className='my-2' controlId="groupActivity">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                                                            <div className='d-flex flex-row   w-100'>



                                                                <Form.Check
                                                                    type="checkbox"
                                                                    label="Is Active"
                                                                    name="active"
                                                                    checked={isActive}
                                                                    onChange={(e) => {
                                                                        setIsActive(!isActive)
                                                                    }}

                                                                    className="my-0 mx-1 input-box-events form-control-sm"
                                                                />




                                                            </div>
                                                        </div>


                                                    </Form.Group>

                                                </Col>
                                            </Row>
                                        </Col>




                                    </Row>



                                </Card.Body>
                            </Card>



                            <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit' >
                                    <GrIcon.GrUploadOption className='mx-1' style={{ width: '15px', height: '15px' }} /> | Update Email Group
                                </Button>
                            </div>
                        </Form>



                    </div>


                </Modal>}



                <div style={{ fontSize: '12px' }} className='table-responsive'>

                    <Form onSubmit={handleGroupSubmit}>

                        <Card>
                            <Card.Body >
                                <Row>

                                    <Col className='my-1' md={6}>
                                        <Form.Group controlId="name">
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                <div className='mx-2 FormLabels'>
                                                    <p style={{ margin: 0 }}>Name<span style={{ color: "red" }}> *</span></p>
                                                </div>

                                                <Form.Control
                                                    className=' input-box-events form-control-sm'
                                                    type="text"
                                                    required
                                                    placeholder="Enter name"
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}

                                                />
                                            </div>

                                        </Form.Group>
                                    </Col>

                                    <Col className='my-1' md={6}>
                                        <Form.Group controlId="email">
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                <div className='mx-2 FormLabels'>
                                                    <p style={{ margin: 0 }}>Email<span style={{ color: "red" }}> *</span></p>
                                                </div>

                                                <Form.Control
                                                    className=' input-box-events form-control-sm'
                                                    type="email"
                                                    required
                                                    placeholder="Enter email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}

                                                />
                                            </div>

                                        </Form.Group>
                                    </Col>

                                    <Col className='my-1' md={6}>


                                        <Form.Group className='my-2' controlId="starts">
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                <div className='mx-2 FormLabels'>
                                                    <p style={{ margin: 0 }}>Starts</p>
                                                </div>
                                                <Form.Control
                                                    className=' input-box-events form-control-sm'
                                                    type="datetime-local"
                                                    name="starts"
                                                    value={starts}
                                                    onChange={(e) => { setStarts(e.target.value) }}
                                                    max={eventInfo?.endtime?.slice(0, 16)}
                                                />

                                            </div>


                                        </Form.Group>


                                    </Col>

                                    <Col className='my-1' md={6}>


                                        <Form.Group className='my-2' controlId="end">
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                <div className='mx-2 FormLabels'>
                                                    <p style={{ margin: 0 }}> Ends </p>
                                                </div>
                                                <Form.Control
                                                    className=' input-box-events form-control-sm'
                                                    type="datetime-local"
                                                    name="ends"
                                                    value={ends}
                                                    onChange={(e) => { setEnds(e.target.value) }}
                                                    max={eventInfo?.endtime?.slice(0, 16)}
                                                />

                                            </div>


                                        </Form.Group>


                                    </Col>



                                </Row>



                            </Card.Body>
                        </Card>



                        <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                            <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit' >
                                <GiIcon.GiArchiveRegister className='mx-1' style={{ width: '15px', height: '15px' }} /> | Create Email Group
                            </Button>
                        </div>
                    </Form>

                    <div style={{ width: '100%', textAlign: 'start' }}>
                        <p style={{ margin: 0, fontWeight: 'bold' }}>Complimentary Ticket Email Groups</p>
                    </div>
                    {ctgroups?.length > 0 ?
                        <Table striped hover style={{ cursor: "pointer" }}>
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Name</th>
                                    <th> Status</th>
                                    <th> Email </th>
                                    <th> Issued </th>
                                    <th> Entered </th>
                                    <th> Starts </th>
                                    <th> Ends </th>
                                    <th>Operations </th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedGroups?.map((data, index) => (
                                    <tr key={index} onClick={() => { }}>
                                        <td >{((currentPage * 5) - postsPerPage) + (index + 1)}</td>
                                        <td >{data.name}</td>
                                        <td >{!(data.status) ? <ImCross style={{ height: "12px", width: "12px", color: 'red' }} /> : <TiIcons.TiTick style={{ height: "20px", width: "20px", color: 'green' }} />}</td>
                                        <td >{data?.email}</td>
                                        <td style={{ textAlign: 'center' }} >{data?.ComplementaryTickets.length}</td>
                                        <td style={{ textAlign: 'center' }} >{data?.ComplementaryTickets?.filter((data) => { return data?.entered })?.length}</td>
                                         <td style={{ fontSize: '10px', whiteSpace: 'nowrap' }}>{getformattedDate(data.starts)}</td>
                                         <td style={{ fontSize: '10px', whiteSpace: 'nowrap' }}>{getformattedDate(data.ends)}</td>
                                        <td>


                                            <GrIcon.GrUploadOption className='mx-1' title='Update Email Group Details' style={{ height: "15px", width: "15px" }} onClick={() => {
                                                setName(data.name)
                                                setEmail(data?.email)
                                                setUpdateGroupInfo(true)
                                                setIsActive(data.status)
                                                setGroupRowData(data)

                                            }} />

                                            {(data.status) ?

                                                <MdIcons.MdEmail className='mx-1' title='New tickets' style={{ height: "15px", width: "15px" }} onClick={() => {

                                                    setEmail(data?.email)
                                                    setShowSendMails(true)
                                                    setGroupRowData(data)

                                                }} /> :

                                                <></>

                                            }




                                            <GrIcon.GrView className='mx-1' title='View tickets' style={{ height: "15px", width: "15px" }} onClick={() => {

                                                setEmail(data?.email)
                                                setViewTickets(true)
                                                setGroupRowData(data)

                                            }} />

                                            <MdIcons.MdUpload className='mx-1' title='Upload Ticket Template' style={{ height: "15px", width: "15px" }} onClick={() => {

                                                setGroupImageLink(data.templateImage)
                                                setSelectedCtgid(data.id)
                                                setShowTemplateUpload(true)

                                            }} />



                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        :

                        <p>No Email Groups added.</p>

                    }
                    {ctgroups?.length > 0 && (
                        <Pagination
                            postsPerPage={postsPerPage}
                            totalPosts={ctgroups.length}
                            paginate={paginate}
                            currentPage={currentPage}
                        />
                    )}


                </div>

            </Container>
        );
    }
};

export default ComplementaryTickets;
